@use "src/styles" as *;

.talent-dot {
    --c-dot: var(--c-grey-light);
    --dot-size: min(3.75rem, 30vmin);

    @extend %flex-align-center;
    @include circle(var(--dot-size));

    position: relative;
    padding: .25rem;
    font-size: .875rem;
    font-weight: var(--fw-normal);
    color: var(--c-always-black);
    cursor: default;
    background-color: var(--c-dot);
    border: 1px solid var(--c-grey-light);

    &:enabled:hover,
    &:enabled:focus {
        background-color: var(--c-dot);
    }

    &:disabled {
        cursor: default;
    }

    &--is-normal,
    &--is-high,
    &--is-excellent {
        border-color: var(--c-primary);
        cursor: pointer;
        transition: all .1s ease;

        &:enabled:hover,
        &:enabled:focus {
            transform: scale(1.05);
        }
    }

    &--is-normal {
        --c-dot: rgba(var(--c-primary-rgb), .4);
        --dot-size: min(6.5rem, 30vmin);
    }

    &--is-high {
        --c-dot: rgba(var(--c-primary-rgb), .7);
        --dot-size: min(7.5rem, 30vmin);
    }

    &--is-excellent {
        --c-dot: var(--c-primary);
        --dot-size: min(9.875rem, 30vmin);
    }

    
    &__label {
        display: flex;
        flex-direction: column;
        width: var(--dot-size);
        line-height: 1.125rem;
        text-align: center;
        overflow-wrap: break-word;
        hyphens: auto;
    }

    &__icon {
        margin-top: .5rem;
    }
}
