.vacancy-matching-competencies-skeletons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 0;

    &__tag {
        display: block;
        width: 4rem;
        height: 2.25rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        padding: .5rem .75rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);
    }
}
