@use "src/styles" as *;

.occupation-competencies-card {

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__add-button {
        @extend %button--secondary;
    }

    &__null-state {
        margin-top: 1rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        max-width: var(--paragraph-max-width);
        gap: 1rem;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &__list-item {
        padding: .5rem;
        list-style-type: none;
        font-size: .875rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }
}
