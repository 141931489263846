.logo-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    &__item {
        list-style-type: none;

        & + & {
            margin-left: 2rem;
        }
    }

    &__logo {
        width: auto;
        height: 1.5rem;
    }
}
