.competency-selected-options {

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        margin: 1rem 0 0;
        padding: 0;
    }
}
