.block-radio-list {
    display: block;

    &:focus-within &__items {
        box-shadow: var(--focus-ring);
    }

    &__label {
        margin-bottom: .625rem;
    }

    &__items {
        display: flex;
        border-radius: var(--border-radius);

        &--has-error {
            border: 2px solid var(--c-negative);
        }
    }

    &__error-label {
        margin-top: .5rem;
    }
}
