@use "src/styles" as *;

.occupation-card {

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__link {
        color: var(--c-black);
        text-decoration: none;

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
        }
    }

    &__title {
        font-size: 1.125rem;
    }

    &__description {
        margin-top: .5rem;
        white-space: pre-wrap;
    }

    &__matched-skills {
        display: flex;
        align-items: center;
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
        border-top: var(--line);

        @include for-size(tablet-portrait-up) {
            margin: 0 0 0 2rem;
            padding: 0 0 0 2rem;
            border-top: none;
            border-left: var(--line);
        }
    }

    &__progress-circle {
        @include circle(6rem);
    }
}
