@use "src/styles" as *;

.registration-page {

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        width: auto;
        height: 3rem;
    }

    &__card {
        max-width: 40rem;
        margin-top: 1.5rem;
        padding-top: 2.5rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 2.5rem;
        }
    }

    &__title {
        font-size: 1.5rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__form {
        margin-top: 2rem;
    }
}
