@use "src/styles" as *;

.workplace-preferences-card {

    &__description {
        --communications-styles-paragraph-display: none;

        &--is-expanded {
            --communications-styles-paragraph-display: block;
        }
    }

    &__paragraph {
        
        & + & {
            margin-top: 1rem;
        }

        &:nth-child(n+4) {
            display: var(--communications-styles-paragraph-display);
        }
    }

    &__collapse-button {
        @extend %button--textual;

        margin-top: 1.5rem;
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }
}
