.not-found-page {

    &__paragraph {
        margin-top: 1rem;
    }

    &__button {
        margin-top: 2rem;
    }
}
