@keyframes candidate-illustration-neck {
    to { transform: rotate(-3deg) }
}

@keyframes candidate-illustration-shoulder {
    to { transform: rotate(6deg) }
}

@keyframes candidate-illustration-elbow {
    to { transform: rotate(4deg) }
}

@keyframes candidate-illustration-wrist {
    to { transform: rotate(-8deg) }
}

@keyframes candidate-illustration-notification {
    from { transform: rotate(6deg) }
    to { transform: rotate(2deg) }
}
