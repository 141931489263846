@use "src/styles" as *;

.development-plan-card {
    padding: 1.5rem 1.5rem 1rem;

    &__header {
        display: flex;
        justify-content: flex-start;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
        word-wrap: break-word;
        overflow: hidden;
    }

    &__actions-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin-left: auto;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            row-gap: 0;
        }
    }

    &__action-button {
        @include circle(3rem);

        padding: 1rem;
        color: var(--c-grey-dark);
        background: transparent;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }

        & + & {
            margin-top: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: .5rem;
            }
        }

        &--is-delete {

            &:hover,
            &:focus {
                color: var(--c-negative);
                background-color: rgba(var(--c-negative-rgb), .2);
            }
        }
    }

    &__table {
        margin-top: 1.25rem;
    }

    &__row {
        position: relative;
        height: 3rem;
    }

    &__cell {
        @include column-width(12rem);

        &:nth-of-type(2) { @include column-width(14rem); }
        &:nth-of-type(3) { @include column-width(20rem); }

        color: var(--c-grey-dark);

        &--name {
            color: var(--c-black);
            font-weight: var(--fw-medium);
        }
    }

    &__hidden-cell {
        @include column-width(0);
    }

    &__fixed-cell {
        @include column-width(4rem);

        justify-content: center;
        position: sticky;
        right: 0;
        margin-left: auto;
        z-index: 20;
        background-color: var(--c-white);
    }

    &__fixed-cell-content {
        padding: 0 1rem;
    }

    &__redirect-button {
        @extend %button--no-background;
        @extend %click-extend;

        z-index: 10;
    }

    &__create-evaluation-button {
        @extend %button--no-background;

        padding: 1rem 0;
        color: var(--c-primary);
    }
}
