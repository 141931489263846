@use "src/styles" as *;

.maintenance-illustration {
    --maintenance-illustration-animation-duration: 2s;

    --maintenance-illustration-c-background: #e6e8ea;
    --maintenance-illustration-c-computer: #5f6974;
    --maintenance-illustration-c-computer-accents: #f2f2f2;
    --maintenance-illustration-c-screen: #f9f9f9;
    --maintenance-illustration-c-warning-triangle: #ff9900;
    --maintenance-illustration-c-top-desk: #a2a9b0;
    --maintenance-illustration-c-bottom-desk: #5f6974;
    --maintenance-illustration-c-top-keyboard: #f9f9f9;
    --maintenance-illustration-c-bottom-keyboard: #5f6974;
    --maintenance-illustration-c-top-mouse: #5f6974;
    --maintenance-illustration-c-bottom-mouse: #f9f9f9;
    --maintenance-illustration-c-mouse-accent: #f2f2f2;

    height: 12rem;

    &__background {
        fill: var(--maintenance-illustration-c-background);
    }

    &__computer {
        fill: var(--maintenance-illustration-c-computer);
    }

    &__computer-accents {
        fill: var(--maintenance-illustration-c-computer-accents);
    }

    &__screen {
        fill: var(--maintenance-illustration-c-screen);
    }

    &__warning-triangle {
        @extend %transforming-svg;

        animation: maintenance-illustration-warning-triangle ease-in-out alternate infinite;
        animation-duration: var(--maintenance-illustration-animation-duration);
        fill: var(--maintenance-illustration-c-warning-triangle);
    }

    &__top-desk {
        fill: var(--maintenance-illustration-c-top-desk);
    }

    &__bottom-desk {
        fill: var(--maintenance-illustration-c-bottom-desk);
    }

    &__top-keyboard {
        fill: var(--maintenance-illustration-c-top-keyboard);
    }

    &__bottom-keyboard {
        fill: var(--maintenance-illustration-c-bottom-keyboard);
    }

    &__top-mouse {
        fill: var(--maintenance-illustration-c-top-mouse);
    }

    &__bottom-mouse {
        fill: var(--maintenance-illustration-c-bottom-mouse);
    }

    &__mouse-accent {
        fill: var(--maintenance-illustration-c-mouse-accent);
    }
}
