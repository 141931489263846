@use "src/styles" as *;

.development-plan-evaluation-skeletons {

    &__breadcrumbs {
        max-width: 100%;
        height: .875rem;

        @include for-size(tablet-landscape-up) {
            max-width: 40%;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        width: 50%;
    }

    &__title {
        height: 2rem;
    }

    &__date {
        max-width: 20%;
        margin-top: .5rem;
    }

    &__action-buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 2rem;
        width: 100%;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            width: 50%;
        }
    }

    &__delete-button {
        width: 17rem;
        height: 3rem;
    }

    &__add-button {
        margin: 1rem 0 0 0;
        width: 17rem;
        height: 3rem;

        @include for-size(tablet-landscape-up) {
            margin: 0 0 0 1rem;
        }
    }

    &__item-list {
        margin-top: 2.25rem;
        padding: 0;
        list-style-type: none;
    }

    &__item {
        padding: 2rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);

        & + & {
            margin-top: 1rem;
        }
    }

    &__item-title {
        height: 1rem;
        max-width: 100%;

        @include for-size(tablet-landscape-up) {
            max-width: 50%;
        }
    }

    &__item-header {
        padding-bottom: 2rem;
        border-bottom: 1px solid var(--c-grey);
    }

    &__item-label {
        margin-top: 2rem;
    }

    &__item-input {
        margin-top: 1rem;
    }

    &__item-toggle {
        margin-top: 1.5rem;
        max-width: 10rem;
    }
}
