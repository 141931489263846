.competencies-card {
    
    &__level {
        margin-top: 1.5rem;

        & + & {
            padding-top: 1.5rem;
            border-top: var(--line);
        }
    }

    &__level-title {
        color: var(--c-grey-dark);

        &--is-positive-level {
            color: var(--c-primary);
        }
    }

    &__level-description {
        margin-top: .5rem;
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1.5rem 0 0;
        padding: 0;
        list-style-type: none;
    }
}
