@use "src/styles" as *;

$component: "skill-option";

.#{$component} {
    list-style-type: none;

    &__button {
        padding: 0;
        padding-left: 1rem;
        padding-right: .5rem;
        color: var(--c-text);
        font-weight: var(--fw-normal);
        line-height: 1.5;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
        background-color: var(--c-white);

        &:enabled:hover,
        &:enabled:focus {
            background-color: var(--c-white);

            .#{$component}__icon-wrapper {
                background-color: var(--c-grey-light);
            }
        }

        &--is-selected {
            background-color: var(--c-grey-light);

            &:enabled:hover,
            &:enabled:focus {
                background-color: var(--c-grey-light);

                .#{$component}__icon-wrapper {
                    background-color: var(--c-white);
                }
            }
        }
    }

    &__label {
        padding: .5rem 0;
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(1.5rem);

        margin-left: .5rem;
        padding: .25rem;
    }
}
