@use "src/styles" as *;

.locale-switcher-list {
    overflow: hidden;
    color: var(--c-grey-dark);
    pointer-events: none;
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);
    transform: translateY(-.5rem);
    opacity: 0;
    transition: transform .1s ease, opacity .1s ease;

    &--is-active {
        pointer-events: all;
        transform: translateY(0);
        opacity: 1;
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        width: 100%;
        list-style-type: none;

        & + & {
            border-top: var(--line);
        }
    }

    &__button {
        @extend %button--no-background;

        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 1.25rem 1rem;
        font-size: .875rem;
        color: currentColor;
        border-radius: 0;

        &:disabled {
            color: var(--c-always-white);
            background-color: var(--c-primary);
        }
    }

    &__flag-container {
        display: flex;
        align-items: center;
        width: 2rem;
        margin-right: .5rem;
    }

    &__flag {
        max-width: 100%;
    }
}
