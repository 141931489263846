@use "src/styles" as *;

.expandable-tag-list {

    &__button-wrapper {
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }

    &__overflow-indicator {
        @extend %flex-align-center;
        @include circle(1.25rem);

        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-always-white);
        background-color: var(--c-primary);
    }

    &__expand-button {
        @extend %button--textual;

        margin-left: .5rem;
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }
}
