@use "src/styles" as *;

.delete-account-form-card {

    &__title {
        font-size: 1.5rem;
    }

    &__loader {
        margin-top: 1.5rem;
    }

    &__wrapper {
        margin-top: 1.5rem;
    }

    &__button {
        @extend %button--delete;

        margin-top: 2rem;
    }
}
