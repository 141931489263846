.number-input {
    display: block;

    &__label {
        margin-bottom: .625rem;
    }

    &__field-wrapper {
        display: flex;
    }

    &__field {
        -moz-appearance: textfield;
        border-right: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    &__control-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__control-button {
        position: relative;
        z-index: 1;
        padding: 0 1rem;
        height: 100%;
        border-radius: 0;

        &--plus {
            border-radius: 0 var(--border-radius) 0 0;
        }

        &--minus {
            border-radius: 0 0 var(--border-radius) 0;
        }
    }

    &__error-label {
        margin-top: .5rem;
    }
}
