@use "src/styles" as *;

.disclaimer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &--is-positive &__icon-wrapper {
        color: var(--c-always-white);
        background-color: var(--c-positive);
    }

    &--is-warning &__icon-wrapper {
        background-color: var(--c-warning);
    }

    &--is-negative &__icon-wrapper {
        color: var(--c-always-white);
        background-color: var(--c-negative);
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        min-width: 2.5rem;
        color: var(--c-always-black);
        background-color: var(--c-grey-light);
    }

    &__icon {
        max-width: 1rem;
        max-height: 1rem;
    }

    &__text-wrapper {
        margin-top: 1rem;
        padding: .5rem 0;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__title {
        color: var(--c-grey-dark);
    }

    &__description {
        margin-top: 1rem;
    }
}
