@use "src/styles" as *;

.searchable-value-input-option {
    list-style-type: none;

    & + & {
        border-top: var(--line);
    }

    &__button {
        @extend %button--no-background;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 1rem;
        text-align: left;
        color: var(--c-grey-dark);
        border-radius: 0;

        &:enabled:hover,
        &:enabled:focus {
            background-color: var(--c-grey-light);
        }
    }

    &__label {

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__secondary-label {
        margin-top: .25rem;
        font-size: .875rem;
        color: var(--c-grey);
    }
}
