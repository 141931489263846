@use "src/styles" as *;

.organisation-detail-header-skeletons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        gap: 2rem;
    }

    &__card {
        width: auto;
        max-width: 30rem;
        height: fit-content;

        @include for-size(tablet-landscape-up) {
            width: 30rem;
        }
    }

    &__name {
        height: 1.5rem;
    }

    &__location {
        margin-top: 1rem;
    }

    &__external-id-title {
        height: 1.5rem;
    }

    &__external-id-description {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__copyable-value {
        height: 3.375rem;
        margin-top: 1rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }
}
