.loading-spinner {
    --loading-spinner-size: 1.5rem;

    width: var(--loading-spinner-size);
    height: var(--loading-spinner-size);
    border-radius: var(--loading-spinner-size);
    border: .25rem solid currentColor;
    border-left-color: transparent;
    animation: spin 1.5s linear infinite;
}
