@use "src/styles" as *;

.call-to-action-null-state {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(4.125rem);

        background-color: var(--c-grey-light);
    }

    &__icon {
        height: 1.5rem;
        color: var(--c-grey-dark);
    }

    &__paragraph {
        margin-top: 1.5rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__sub-paragraph {
        margin-top: .5rem;
        color: var(--c-grey-dark);
    }

    &__button {
        @extend %button;

        margin-top: 1rem;
    }

    &__button-icon {
        margin-right: .75rem;
    }
}
