@forward "animation-library";

@keyframes spin {
    to { transform: rotate(1turn) }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes pulse {
    from {
        transform: scale(.8);
        opacity: .9;
    }

    to {
        transform: scale(1.07);
        opacity: 0;
    }
}

@keyframes skeleton-load {
    from  { transform: translateX(-100%); }
    to    { transform: translateX(100%); }
}

@keyframes load-dashboard-progress {
    from { stroke-dashoffset: 100; }
}
