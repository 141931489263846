@use "src/styles" as *;

.maintenance {
    @extend %flex-align-center;

    width: 100%;
    height: 100vh;
    padding: var(--s-page-padding);
    background: var(--c-grey-light);

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 40rem;
        padding: var(--s-page-padding);
        text-align: center;
    }

    &__title,
    &__button-refresh {
        margin-top: 2rem;
    }

    &__description {
        margin-top: 1rem;
    }
}
