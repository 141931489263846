@use "src/styles" as *;

.tabs-navigation {
    width: 100%;

    &__list {
        display: flex;
        flex-direction: column;
        margin: 0;
        border-bottom: var(--line);
        padding: 0;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__indicator {
        @include circle(.8rem);
        position: absolute;
        border: 2px solid var(--c-white);
        background-color: var(--c-warning);

        @include for-size(tablet-portrait-up) {
            top: .75rem;
            right: -.75rem;
        }
    }

    &__list-item {

        & + & {
            margin: 1rem 0 0 0;

            @include for-size(tablet-landscape-up) {
                margin: 0 0 0 2rem;
            }
        }
    }
}
