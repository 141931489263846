@use "src/styles" as *;

.tma-analysis-settings-form {

    &__disclaimer {
        margin-bottom: 1.5rem;
    }

    &__error-message {
        margin-bottom: 1.5rem;
    }

    &__period-available-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        & > * + * {
            margin-top: 1.5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__input {
        width: 100%;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
