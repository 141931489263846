.school-experience-form {

    & > * + * {
        margin-top: 1.5rem;
    }

    &__input {
        width: 100%;
    }

    &__checkbox {
        width: auto;
    }

    &__disclaimer {
        margin-top: 2rem;
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
