@use "src/styles" as *;

.update-skills-passport-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
        font-size: 1.125rem;
    }

    &__update-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: .5rem;

        @include for-size(desktop-up) {
            flex-direction: row;
        }
    }

    &__last-updated {

        @include for-size(desktop-up) {
            max-width: 18rem;
        }
    }

    &__button {
        @extend %button--secondary;

        margin-top: 1rem;
        padding: 1rem;
        white-space: nowrap;

        @include for-size(desktop-up) {
            align-self: flex-end;
            margin-left: 1.5rem;
        }
    }
}
