@use "src/styles" as *;

.development-plan-skill-card {
    padding: 1.5rem 2rem;

    &__header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-left: -1rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        width: 100%;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
        }
    }

    &__toggle-button {
        @extend %button--no-background;

        padding: 1rem;
        font-weight: var(--fw-medium);
        font-size: 1.125rem;
        text-transform: initial;
        text-align: left;
        color: var(--c-grey-dark);

        &:disabled {
            color: var(--c-grey-light);
        }
    }

    &__toggle-icon {
        transition: transform .25s ease-out;
        transform: scaleY(1);

        &--is-expanded {
            transform: scaleY(-1);
        }
    }

    &__save-button {
        @extend %button--warning;

        margin-right: 1rem;
    }

    &__title {
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__action-buttons {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-left: auto;
        border-bottom: var(--line);
        padding-bottom: 1rem;

        @include for-size(tablet-landscape-up) {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__complete-button {
        @extend %button--secondary;

        margin-right: 1rem;
    }

    &__action-button {
        @include circle(3rem);

        padding: 1rem;
        color: var(--c-grey-dark);
        background: transparent;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }

        & + & {
            margin-top: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: .5rem;
            }
        }

        &--is-delete {

            &:hover,
            &:focus {
                color: var(--c-negative);
                background-color: rgba(var(--c-negative-rgb), .2);
            }
        }
    }

    &__expandable-section {
        overflow: hidden;
        max-height: 0;
        transition: max-height .5s ease-in-out;

        &--is-expanded {
            max-height: 500rem;
        }
    }

    &__question {
        margin-top: 1rem;

        & + & {
            margin-top: 2rem;
        }
    }
}
