.logout-message {

    &__title {
        font-size: 1.5rem;
    }

    &__description {
        margin-top: 1.5rem;
    }

    &__countdown-message {
        display: inline-block;
        margin-top: 1rem;
        padding: .5rem 1rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }

    &__confirm-button {
        margin-top: 2rem;
    }
}
