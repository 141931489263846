@use "src/styles" as *;

.filter-controls {
    display: flex;
    flex-direction: row-reverse;

    &__filter-button,
    &__reset-button {
        @extend %button--secondary;

        height: 3.5rem;
    }

    &__reset-button {
        margin-right: 1.5rem;
    }
}
