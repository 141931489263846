@use "src/styles" as *;

.counselor-form {

    &__row {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }

        & + & {
            margin-top: 1rem;
        }
    }

    &__column {
        width: 100%;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }

        &--is-last-name-prefix {

            @include for-size(tablet-portrait-up) {
                width: 40%;
            }
        }
    }

    &__input {
        display: block;
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
