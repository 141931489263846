@use "src/styles" as *;

.pagination {
    --pagination-block-size: 2.5rem;

    display: flex;
    justify-content: center;

    &__controls {
        display: flex;
        align-items: center;
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__ellipsis {
        @extend %flex-align-center;

        width: var(--pagination-block-size);
        height: var(--pagination-block-size);
    }
}
