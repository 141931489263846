@use "src/styles" as *;

.organisation-detail-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        gap: 2rem;
    }

    &__card {
        position: relative;
        width: auto;
        max-width: 30rem;
        height: 100%;

        @include for-size(tablet-landscape-up) {
            width: 30rem;
        }
    }

    &__name {
        font-size: 1.125rem;
    }

    &__location {
        margin-top: .5rem;
    }

    &__edit-button {
        @extend %button--no-background;

        position: absolute;
        top: 1rem;
        right: 1rem;
        color: var(--c-text);
    }

    &__external-id-title {
        font-size: 1.125rem;
    }

    &__external-id-description {
        margin-top: .5rem;
    }

    &__copyable-value {
        margin-top: 1rem;
    }
}
