.coaching-advice-card {

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__description {
        margin-top: .5rem;
    }

    &__competency-select {
        margin-top: 1.5rem;
        max-width: 18rem;
    }

    &__advice-content {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: var(--line);
    }

    &__advice-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__advice-list-item {

        & + & {
            margin-top: .5rem;
        }
    }
}
