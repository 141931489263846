%button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1rem 1.375rem;
    font: inherit;
    font-weight: var(--fw-medium);
    line-height: 1;
    color: var(--c-always-white);
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--c-primary);

    &:hover,
    &:focus {
        background-color: var(--c-primary-dark);
    }

    &:disabled {
        color: var(--c-grey-dark);
        cursor: not-allowed;
        background-color: var(--c-grey-light);
    }
}

%button--secondary {
    color: var(--c-grey-dark);
    border: 1px solid var(--c-grey-light);
    background-color: var(--c-white);

    &:hover:enabled,
    &:focus:enabled {
        background-color: rgba(var(--c-grey-light-rgb), .2);
    }
}

%button--no-background {
    background: none;

    &[href]:hover,
    &[href]:focus,
    &:disabled,
    &:enabled:hover,
    &:enabled:focus {
        background: none;
    }
}

%button--transparent-background {
    @extend %button--no-background;

    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
        background-color: currentColor;
        opacity: .1;
    }

    &[href]:hover,
    &[href]:focus {

        &::after {
            opacity: .15;
        }
    }

    &:enabled:hover,
    &:enabled:focus {

        &::after {
            opacity: .15;
        }
    }
}

%button--textual {
    @extend %button--no-background;

    display: inline-flex;
    padding: 0;
    font-weight: inherit;
    line-height: inherit;
    color: currentColor;
}

%button--delete {
    background-color: var(--c-negative);

    &:hover,
    &:focus {
        background-color: var(--c-negative-dark);
    }

    &:disabled {
        color: var(--c-white);
    }
}

%button--warning {
    background-color: var(--c-warning);
    color: var(--c-always-black);

    &:hover,
    &:focus {
        background-color: var(--c-warning-dark);
    }

    &:disabled {
        color: var(--c-white);
    }
}
