@use "src/styles" as *;

.logo-card {
    @extend %flex-align-center;

    padding: 2rem;

    @include for-size(tablet-landscape-up) {
        padding: 4rem;
    }

    @include for-size(desktop-up) {
        padding: 6rem;
    }

    &__logo {
        width: 9.25rem;
    }
}
