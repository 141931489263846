.counselor-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__expand-button {
        position: relative;
        justify-content: center;
        padding-left: 3rem;
        width: 100%;
    }

    &__expand-button-icon {
        position: absolute;
        left: 1.375rem;
        max-width: 1.125rem;
    }

    &__candidate-select {
        width: 100%;
    }

    &__navigation,
    &__candidate-select,
    &__loader,
    &__expand-button:last-of-type {
        margin-top: 1.5rem;
    }
}
