@use "src/styles" as *;

.personality-description-card {

    &__html-wrapper {
        margin-top: 1.5rem;

        p + p {
            display: none;
            margin-top: 1rem;
        }

        &--is-expanded {

            p + p {
                display: block;
            }
        }
    }

    &__collapse-button {
        @extend %button--textual;

        margin-top: 1.5rem;
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }
}
