@use "src/styles" as *;

.skills-passport-header {

    &__action-navigation {
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
        }
    }

    &__profile-cards {
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__card {
        padding: 1.5rem 2rem;

        & + & {
            margin-top: 1.5rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }

        @include for-size(tablet-landscape-up) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
