.incentive-slider-value-illustration {
    height: 2.5rem;

    &__arrow {
        fill: var(--c-primary);
    }

    &__inner-circle {
        fill: var(--c-always-white);
    }

    &__star {
        fill: var(--c-primary-dark);
    }
}
