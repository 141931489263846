@use "src/styles" as *;

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // JavaScript solution, because iOS devices don't calculate 100vh the same way other browsers do
    height: var(--page-viewport-height);
    padding: 4rem 1rem 1.5rem;
    background-color: var(--c-white);
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform .3s ease;

    &--is-open {
        transform: translateX(0%);
    }

    @include for-size(tablet-landscape-up) {
        padding: 1.5rem 1rem;
        transform: none;
    }

    &__navigation:last-of-type {
        margin-bottom: 2rem;
    }

    &__logo {
        margin: auto auto 0;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            justify-self: flex-end;
        }
    }
}
