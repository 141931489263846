@use "src/styles" as *;

.profile-information-card {

    &__skeleton-wrapper {
        min-width: 8rem;
        transform: translateY(.25rem);
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__text-wrapper {
        min-width: 8rem;
    }

    &__user-name {
        font-size: 1.125rem;
    }

    &__location {
        margin-top: .5rem;
        font-weight: var(--fw-regular);
    }
}
