.block-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &--is-disabled {
        cursor: not-allowed;
    }

    &__label-title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__label-description {
        margin: .25rem 0 0;
        color: var(--c-grey);
    }
}
