$component: "descriptive-radio-list";

.#{$component} {

    &__label {
        margin-bottom: .625rem;
    }

    &__options-wrapper {
        display: flex;
        flex-direction: column;

        &--has-error {

            .#{$component}__option {
                border: 2px solid var(--c-negative);
            }
        }
    }

    &__option {

        & + & {
            margin-top: 1rem;
        }
    }

    &__error-label {
        margin-top: .5rem;
    }
}
