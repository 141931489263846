.logo {
    width: 100%;

    &-llo {

        &__shade {
            fill: var(--c-always-black);
            fill-opacity: .1;
        }

        &__primary {
            fill: var(--c-primary);
        }

        &__secondary {
            fill: var(--c-secondary);

            @media (prefers-color-scheme: dark) {
                fill: var(--c-always-white);
            }
        }

        &__black {
            fill: var(--c-black);
        }

        &__grey {
            fill: var(--c-grey-dark);
        }
    }

    &-talentz {

        &__black {
            fill: #242424;

            @media (prefers-color-scheme: dark) {
                fill: var(--c-always-white);
            }
        }
    }

    &-tma {

        &__lime { fill: #afcc46; }
        &__pink { fill: #d24291; }
        &__yellow { fill: #fab400; }
        &__orange { fill: #ed7352; }
        &__blue { fill: #0086c8; }
        &__green { fill: #50b47e; }
        &__grey { fill: #9d9d9d; }
    }
}
