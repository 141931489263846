@use "src/styles" as *;

.default-action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__description {
        padding-right: 2rem;

        @include for-size(tablet-portrait-up) {
            padding-right: 6rem;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: baseline;
        }
    }

    &__action-button {
        @extend %button--warning;
    }

    &__duration {
        color: var(--c-grey);
    }

    &__progress-wrapper {
        @extend %flex-align-center;

        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            align-items: center;
        }
    }
}
