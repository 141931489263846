@use "src/styles" as *;

.development-plan-form {

    & + & {
        margin-top: 1rem;
    }

    &__row {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }

        & + & {
            margin-top: 1rem;
        }
    }

    &__input {
        width: 100%;
    }

    &__column {
        width: 100%;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                width: calc(50% - .5rem);
                margin-top: 0;
                margin-left: 1rem;
            }
        }

        @include for-size(tablet-landscape-up) {
            width: calc(50% - .5rem);
        }
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;

        &--is-editing {
            margin-top: 1rem;
        }
    }

    &__delete-button {
        @extend %button--no-background;

        margin-top: 1rem;
        padding: .75rem;
        font-size: .875rem;
        color: var(--c-negative);
    }
}
