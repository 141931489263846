@use "src/styles" as *;

.incentive-slider {

    &__labels {
        display: flex;
        justify-content: space-between;
    }

    &__label {
        font-size: .875rem;
        font-weight: var(--fw-medium);
    }

    &__slider {
        position: relative;
        width: 100%;
        height: 1rem;
        margin-top: 1rem;
        border-radius: 1rem;
        background-color: var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            min-width: 20rem;
        }
    }

    &__value-illustration {
        position: absolute;
        top: -.875rem;
        left: var(--incentive-slider-value);
        translate: -50% 0;
    }
}
