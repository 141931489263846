.school-experiences-card {

    &__loader,
    &__null-state {
        margin-top: 2rem;
    }

    &__school-experience-wrapper {
        padding: 0;
        margin: 2rem 0 0 0;
    }

    &__school-experience {
        list-style-type: none;

        & + & {
            margin-top: 1.5rem;
            padding-top: 1.5rem;
            border-top: var(--line);
        }
    }
}
