@use "src/styles" as *;

.address-input {

    &__wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__input {

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }

    &__error-label {
        margin-top: 1.5rem;
    }

    &__loader {
        margin-top: 1.5rem;
    }

    &__result-wrapper {
        margin-top: 1.5rem;
    }

    &__result-title {
        font-size: 1rem;
        font-weight: var(--fw-regular);
    }

    &__result-list {
        display: flex;
        flex-direction: column;
        margin: .625rem 0 0;
        padding: 1rem;
        border-radius: var(--border-radius);
        border: 2px solid var(--c-grey);

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__result {
        list-style-type: none;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__result-label {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }
}
