@use "src/styles" as *;

.take-analysis {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
    }

    &__skeletons {
        width: 100%;
    }

    &__description-card {
        width: 100%;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            order: -1;
            margin-top: 0;
        }
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__description {
        margin: 2rem 0 0;

        p {
            margin-top: 1rem;
        }
    }

    &__notice-and-button-wrapper {
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            display: flex;
        }
    }

    &__error-message,
    &__active-message {
        margin-right: 1rem;
    }

    &__start-analysis-button {
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
        }
    }

    &__take-analysis-button {
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
        }
    }

    &__logo-card {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: auto;
            margin-left: 1.5rem;
        }
    }
}
