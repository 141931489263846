@use "src/styles" as *;

.development-plan-notes-skeletons {

    &__action-buttons {
        display: flex;

        @include for-size(tablet-landscape-up) {
            justify-content: flex-end;
        }
    }

    &__add-note-button {
        max-width: 15rem;
        height: 3rem;
    }

    &__list {
        margin: 1rem 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {
        display: flex;

        & + & {
            margin-top: 1rem;
        }
    }

    &__note-content-wrapper {
        flex: 1;
    }

    &__note-content {

        & + & {
            margin-top: .5rem;
        }
    }

    &__updated-at {
        height: .875rem;
        margin-top: 1rem;
    }

    &__controls-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__control-button {
        @include circle(3rem);

        border: 1px solid var(--c-grey-light);

        & + & {
            margin-top: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: .5rem;
            }
        }
    }
}
