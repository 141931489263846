@forward "button";
@forward "html";
@forward "input";
@forward "svg";

%flex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%click-extend {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%skeleton-fade {
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to right,
            rgba(var(--c-white-rgb), 0) 0%,
            rgba(var(--c-white-rgb), .5) 40%,
            rgba(var(--c-white-rgb), 0) 60%
        );
        animation: skeleton-load 2s ease infinite;
    }
}
