@use "src/styles" as *;

.action-item {

    &__card {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0;
    }

    &__header-button {
        @extend %button--no-background;

        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        padding: 1.5rem 2rem;
        text-align: left;
        line-height: 1.5;

        &:disabled {
            opacity: .5;
        }
    }

    &__index {
        @extend %flex-align-center;
        @include circle(2rem);

        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
        background-color: var(--c-grey-light);

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: .125rem;
            background-color: var(--c-grey-light);
            border-radius: var(--border-radius);
        }

        &::before {
            top: 0;
            height: 1rem;
        }

        &::after {
            bottom: 0;
            height: calc(100% - 4.25rem);
        }

        &--is-active {
            color: var(--c-always-white);
            background-color: var(--c-primary);

            &::before {
                background-color: var(--c-primary);
            }
        }

        &--is-completed {
            color: var(--c-primary);
            background-color: rgba(var(--c-primary-rgb), .15);

            &::before,
            &::after {
                background-color: var(--c-primary);
            }
        }

        &--is-first-item::before,
        &--is-last-item::after {
            background-color: transparent;
        }
    }

    &__check-icon {
        width: .875rem;
    }

    &__header-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-left: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: baseline;
            width: 100%;
            margin-left: 2rem;
        }
    }

    &__header-extra-wrapper {
        display: flex;
        align-items: baseline;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex: 1;
        }
    }

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);

        &--is-active {
            color: var(--c-black);
        }
    }

    &__optional-label {
        color: var(--c-grey);
        font-weight: var(--fw-regular);
    }

    &__toggle-label {
        margin-left: auto;
        font-size: .875rem;
        font-weight: var(--fw-regular);
        color: var(--c-grey);
        padding: .5rem;
        border: 1px solid var(--c-grey-light);
    }

    &__content {
        padding: 2rem;
        padding-top: 0;
        padding-left: 5rem;

        @include for-size(tablet-portrait-up) {
            padding-left: 6rem;
        }
    }
}
