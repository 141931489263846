.navigation {
    width: 100%;

    &__list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
}
