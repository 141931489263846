@use "src/styles" as *;

.circle-progress {
    @include square(8.75rem);

    position: relative;

    &--neutral {  color: var(--c-grey) }
    &--positive { color: var(--c-positive) }
    &--warning {  color: var(--c-warning) }
    &--negative { color: var(--c-negative) }

    &__svg {
        transform: rotate(-90deg);
    }

    &__path {
        fill: none;
        stroke: var(--c-grey-light);
        stroke-width: 2;

        &--meter {
            stroke: currentColor;
            stroke-dasharray: 101;
            stroke-dashoffset: var(--circle-progress-value);
            transition: stroke-dashoffset 1s ease;
            animation: load-dashboard-progress 1s ease;
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(5rem);

        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--c-white);
        box-shadow: 0 0 4px 4px rgba(var(--c-grey-light-rgb), .5);
        transform: translate(-50%, -50%);
    }

    &__icon {
        min-height: 1.25rem;
        color: var(--c-grey-dark);
    }

    &__tooltip {
        position: absolute;
        top: 0;
        right: 0;
        padding: .25rem .75rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-always-white);
        border-radius: var(--border-radius);
        background-color: var(--c-always-black);
        transform: translateX(25%);
    }
}
