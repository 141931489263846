@use "src/styles" as *;

.talent-description {

    &__content {

        & + & {
            margin-top: 1rem;
        }
    }

    &__title {
        margin: 0;
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__description {
        margin-top: .5rem;
        color: var(--c-grey-dark);
    }

    &__close-dialog-button {
        @extend %button--textual;

        margin-top: 2rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey);
    }
}
