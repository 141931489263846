@use "src/styles" as *;

.vacancy-detail {

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__body {
        width: 100%;
    }

    &__aside {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 20rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }
}
