@use "src/styles" as *;

$component: "checkbox";

.#{$component} {
    display: inline-flex;
    align-items: center;
    color: var(--c-grey-dark);
    cursor: pointer;

    &--is-disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus ~ .#{$component}__box {
            box-shadow: var(--focus-ring);
        }

        &:checked ~ .#{$component}__box {

            &::after {
                opacity: 1;
                transform: scale(.625);
            }
        }
    }

    &__box {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: var(--border-radius);
        border: 1px solid currentColor;

        &::after {
            @extend %click-extend;

            content: "";
            border-radius: inherit;
            background-color: currentColor;
            opacity: 0;
            transform: scale(0);
            transition: opacity .1s ease, transform .1s ease;
        }
    }

    &__label {
        margin-left: 1rem;
        user-select: none;
    }
}
