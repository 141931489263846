@use "src/styles" as *;

.floating-card {
    position: relative;

    &::after {
        @extend %click-extend;

        content: "";
        z-index: 1;
        border-radius: var(--border-radius);
        background-color: rgba(var(--c-grey-light-rgb), .2);
        box-shadow: var(--box-shadow);
        transition: opacity .1s ease;
        opacity: 0;
    }

    &:hover::after,
    &:focus-within::after {
        opacity: 1;
    }

    &:hover &__card,
    &:focus-within &__card {
        transform: translateY(-.25rem);
    }

    &__card {
        position: relative;
        z-index: 2;
        transform: translateY(0);
        transition: transform .1s ease;
    }
}
