@use "src/styles" as *;

.development-plan-header {
    
    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__title {
        font-size: 1.5rem;
        max-width: 75rem;
        word-wrap: break-word;
        overflow: hidden;
    }

    &__edit-button {
        @extend %button--no-background;

        padding: .75rem;
        font-size: .875rem;
        color: var(--c-grey-dark);

        @include for-size(tablet-landscape-up) {
            margin-left: 1.5rem;
        }
    }

    &__subtitle {
        margin-top: .25rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__actions-wrapper {
        
        @include for-size(tablet-landscape-up) {
            position: relative;
            margin-left: auto;
        }
    }

    &__toggle-button {
        white-space: nowrap;
        padding: .75rem;
    }

    &__tabs-navigation {
        margin-top: 1.5rem;
    }

    &__tab {
        max-width: 75rem;
        margin-top: 1.5rem;
    }
}
