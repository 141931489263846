@use "src/styles" as *;

.my-actions-page {

    &__wrapper {
        transform: translateY(-1rem);

        @include for-size(tablet-landscape-up) {
            transform: translateY(-2rem);
        }
    }
}
