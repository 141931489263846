@use "src/styles" as *;

.development-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
    }

    &__title-wrapper {
        width: 100%;
        margin-bottom: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-bottom: 0;
        }
    }

    &__title {
        font-size: 1.5rem;
    }

    &__title-skeleton {
        min-height: 1.9375rem;
    }

    &__subtitle {
        margin-top: .25rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__actions-wrapper {
        position: relative;
        margin-left: 0;

        @include for-size(tablet-landscape-up) {
            margin-left: auto;
        }
    }

    &__toggle-button {
        white-space: nowrap;
    }
}
