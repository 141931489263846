@use "src/styles" as *;

.cache-buster {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 1.5rem;
    transform: translateY(100%);
    transition: transform .3s ease;

    &--is-revealed {
        transform: translateY(0);
    }

    &__card {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 1.5rem;
        border: 1px solid var(--c-primary);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
            padding: .75rem 1.5rem;
        }
    }

    &__description {
        font-weight: var(--fw-medium);
    }

    &__button {
        margin-top: 1rem;
        padding: .75rem 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }
}
