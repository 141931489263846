.internal-occupation-detail-content-list {

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &__list-item {
        padding: .5rem;
        list-style-type: none;
        font-size: .875rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);

        &--is-matched {
            border-color: var(--c-primary);
            background-color: rgb(var(--c-primary-rgb), .05);
        }
    }
}
