@use "src/styles" as *;

.development-plan-note-card {
    position: relative;

    &__header {
        display: flex;
        gap: 1rem;
        align-items: baseline;
    }

    &__meta-wrapper {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: baseline;
            gap: 1rem;
        }
    }

    &__author {
        font-size: .875rem;
        color: var(--c-grey-dark);
    }

    &__last-updated {
        font-size: .875rem;
    }

    &__controls-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
        gap: .5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            margin-left: auto;
        }
    }

    &__control-button {
        @include circle(3rem);

        padding: 1rem;
        color: var(--c-grey-dark);
        background: none;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }
    }

    &__content {
        flex: 1;
        margin-top: .5rem;
        white-space: pre-wrap;
    }
}
