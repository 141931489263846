@use "helpers" as *;

@forward "index";

@forward "typography";
@forward "animations";
@forward "variables";

:focus:not(:focus-visible) {
    outline: none;
}

* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    color: var(--c-grey-dark);
    background-color: var(--c-white);

    &.has-open-menu {
        overflow: hidden;

        @include for-size(tablet-landscape-up) {
            overflow: initial;
        }
    }

    &.has-active-modal {
        overflow: hidden;
    }
}

#app-root,
#portal-root {
    position: relative;
    z-index: 10;
}

#portal-root {
    z-index: 20;
}

h1, h2, h3 {
    margin: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-medium);
    color: var(--c-black);
}

h1 {
    font-size: 2.25rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1rem;
}

p {
    margin: 0;
    font-size: 1rem;
    // TODO: Check if this line-height declaration can be applied to body
    line-height: 1.5;
}

strong {
    font-weight: var(--fw-medium);
}
