@use "src/styles" as *;

.learning-style-graph {
    position: relative;
    width: 11.5rem;

    &__x-axis-left-label,
    &__x-axis-right-label,
    &__y-axis-label {
        font-size: .875rem;
        font-weight: var(--fw-medium);
    }

    &__y-axis-label {
        display: flex;
        justify-content: center;
    }

    &__x-axis-left-label {
        position: absolute;
        top: 5.375rem;
        left: -2rem;
        transform: rotate(-90deg);
    }

    &__x-axis-right-label {
        position: absolute;
        top: 5.375rem;
        right: -1.5rem;
        transform: rotate(90deg);
    }

    &__dots {
        display: flex;
        flex-direction: column-reverse;
        gap: .5rem;
        padding: .5rem 1.5rem;
    }

    &__row {
        display: flex;
        gap: .5rem;
    }

    &__dot {
        @include circle(.5rem);

        background-color: var(--c-grey-light);

        &--is-selected {
            background-color: var(--c-warning); 
        }
    }
}
