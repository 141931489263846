.settings-tabs {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 1rem;

    &__tab {
        margin-top: 1.5rem;
    }
}
