%input {
    width: 100%;
    margin: 0;
    padding: 1rem;
    font: inherit;
    font-weight: var(--fw-regular);
    color: currentColor;
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);
    background-color: var(--c-white);

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &::placeholder {
        color: var(--c-grey);
    }
}
