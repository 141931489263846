@use "src/styles" as *;

.candidate-invite-form {

    & > * + * {
        margin-top: 1.5rem;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        & > * + * {

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__input {
        width: 100%;

        &--last-name-prefix {

            @include for-size(tablet-portrait-up) {
                width: 40%;
            }
        }
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
