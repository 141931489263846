.personal-details-tab {
    max-width: 40rem;

    &__title {
        font-size: 1.5rem;
    }

    &__form {
        margin-top: 2rem;
    }
}
