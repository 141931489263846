@use "src/styles" as *;

.developmentplan-header-skeletons {

    &__header {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 2rem;
        width: 100%;

        @include for-size(desktop-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__title-and-date-wrapper {
        width: 50%;
        min-width: 20rem;
        max-width: 50rem;
    }

    &__title {
        height: 2rem;
    }

    &__date {
        margin-top: 1rem;
        width: 5rem;
        height: 1rem;
    }

    &__action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;

        @include for-size(desktop-up) {
            margin-top: 0;
        }
    }

    &__add-button {
        margin-left: 1rem;
        width: 17rem;
        height: 3rem;
    }
}
