@use "src/styles" as *;

.locale-switcher {
    position: relative;
    border-radius: var(--border-radius);
    background-color: var(--c-white);

    &__toggle-button {
        @extend %button--no-background;

        color: var(--c-black);
        padding: 1rem;
    }

    &__toggle-icon {
        width: 1.5rem;
        max-width: initial;
        height: 1.5rem;
        max-height: initial;
    }

    &__nav {
        position: absolute;
        top: calc(100% + .5rem);
        left: 0;
        z-index: 10;
    }
}
