@use "src/styles" as *;

.organisation-detail-occupation {

    &__header-card {
        margin-top: 1.5rem;
    }

    &__card {
        margin-top: 1rem;
    }
}
