.organisation-detail-tabs-skeletons {
    
    &__label-wrapper {
        display: flex;
        padding-bottom: 1.25rem;
        border-bottom: var(--line);
    }

    &__label {
        max-width: 6rem;

        & + & {
            margin-left: 2rem;
        }
    }
}
