@use "src/styles" as *;

$component: "toggle";

.#{$component} {
    --toggle-width: 3rem;
    --toggle-handle-size: 1rem;

    display: flex;
    align-items: center;

    &__checkbox {
        position: absolute;
        opacity: 0;

        &:focus ~ .#{$component}__label-wrapper .#{$component}__handle-wrapper {
            box-shadow: var(--focus-ring);
        }

        &:checked {

            & ~ .#{$component}__label-wrapper .#{$component}__handle-wrapper {

                &::after {
                    opacity: 1;
                }

                .#{$component}__handle {
                    transform: translateX(0);
                }
            }
        }

        &:disabled {

            & ~ .#{$component}__label-wrapper {
                cursor: not-allowed;
                opacity: .5;

                .#{$component}__handle-wrapper {

                    &:hover {
                        box-shadow: none;
                    }

                    &::after {
                        background-color: var(--c-grey-light);
                        opacity: 1;
                    }

                    .#{$component}__handle {
                        color: var(--c-grey);
                    }
                }
            }
        }
    }

    &__label-wrapper {
        display: flex;
        align-items: center;
    }

    &__label {
        margin-left: .625rem;
        font-weight: var(--fw-medium);
    }

    &__handle-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        width: var(--toggle-width);
        padding: .25rem;
        overflow: hidden;
        cursor: pointer;
        border-radius: var(--border-radius);
        background-color: var(--c-grey);

        &:hover {
            box-shadow: var(--focus-ring);
        }

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 0;
            opacity: 0;
            transition: opacity .1s ease;
            background-color: var(--c-primary);
        }
    }

    &__handle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 10;
        width: 100%;
        height: var(--toggle-handle-size);
        color: var(--c-always-white);
        transform: translateX(calc(#{var(--toggle-handle-size)} - 100%));
        transition: transform .1s ease;

        &::after {
            content: "";
            width: var(--toggle-handle-size);
            height: var(--toggle-handle-size);
            border-radius: var(--border-radius);
            background-color: currentColor;
        }
    }

    &__handle-label {
        flex: 1;
        font-weight: var(--fw-medium);
        text-align: center;
    }
}
