@use "src/styles" as *;

.occupation-search-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;
    width: 100%;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        gap: 1rem;
    }

    &__input-field,
    &__submit-button {

        @include for-size(tablet-portrait-up) {
            height: 3.5rem;
        }
    }

    &__input {
        width: 100%;
    }

    &__input-list {
        position: absolute;
        z-index: 30;
        width: 100%;
        border-color: var(--c-grey-light);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
    }
}
