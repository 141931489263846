@use "src/styles" as *;

.checkbox-list {

    &__label {
        margin-bottom: .625rem;
    }

    &__options-wrapper {
        display: flex;
        flex-direction: column;

        &--has-error {
            padding-left: .75rem;
            border-left: 2px solid var(--c-negative);
        }
    }

    &__option {

        &--is-hidden {
            display: none;
        }

        & + & {
            margin-top: 1rem;
        }
    }

    &__error-label {
        margin-top: .5rem;
    }

    &__toggle-button {
        @extend %button--no-background;

        margin-top: 1.5rem;
        padding: 0;
        color: var(--c-primary);
    }
}
