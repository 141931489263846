.incentives-card {

    &__dimension {
        margin-top: 1.5rem;

        & + & {
            padding-top: 1.5rem;
            border-top: var(--line);
        }
    }

    &__dimension-title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }
}
