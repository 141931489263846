@use "src/styles" as *;

.development-plan-evaluation-null-state {
    @extend %flex-align-center;

    flex-direction: column;
    margin-top: 2rem;
    padding: 4rem 2rem;

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(4rem);

        background-color: var(--c-grey-light);
    }

    &__icon {
        height: 1.125rem;
        color: var(--c-grey);
    }

    &__title {
        margin-top: 2rem;
        color: var(--c-grey-dark);
        font-size: 1rem;
    }

    &__description {
        margin-top: .5rem;
    }
}
