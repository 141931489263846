@use "src/styles" as *;

$component: "search-input";

.#{$component} {
    display: block;

    &--hidden-icon {

        .#{$component}__input {
            border-left: none;
        }
    }

    &__label {
        margin-bottom: .625rem;
    }

    &__wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);

        &--has-error {
            border: 2px solid var(--c-negative);
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
    }

    &__icon {
        margin: 0 1rem;
    }

    &__input {
        border: none;
        border-left: inherit;
        border-radius: 0;
    }

    &__error-label {
        margin-top: .5rem;
    }
}
