.radio-list {

    &__label {
        margin-bottom: .625rem;
    }

    &__options-wrapper {
        display: flex;
        flex-direction: column;

        &--has-error {
            padding-left: .75rem;
            border-left: 2px solid var(--c-negative);
        }
    }

    &__option {

        & + & {
            margin-top: 1rem;
        }
    }

    &__error-label {
        margin-top: .5rem;
    }
}
