@use "src/styles" as *;

.confirm-dialog {

    &__error,
    &__description {
        margin-top: 2rem;
    }

    &__description p {
        margin-top: 1rem;
        word-wrap: break-word;
        overflow: hidden;
    }

    &__buttons {
        margin-top: 3rem;
    }

    &__button {

        &--delete {
            @extend %button--delete;
        }

        &--warning {
            @extend %button--warning;
        }

        &--cancel {
            @extend %button--transparent-background;

            color: var(--c-grey-dark);
        }
    }
}
