.copyable-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    color: var(--c-grey-dark);
    overflow: auto;
    white-space: nowrap;
    border-radius: var(--border-radius);
    background-color: var(--c-grey-light);

    &__copy-button {
        position: sticky;
        right: 0;
        padding: 1.25rem 1rem;
        font-size: .875rem;
        color: var(--c-grey-dark);
        background-color: var(--c-grey-light);

        &:enabled:hover,
        &:enabled:focus {
            text-decoration: underline;
            color: var(--c-black);
            background-color: var(--c-grey-light);
        }
    }
}
