.competencies-form {

    &__input {
        display: block;
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
