.development-plan-card-question {

    & + & {
        border-top: var(--line);
        padding-top: 2rem;
    }

    &__input {
        margin-top: 1rem;
    }

    &__label {
        color: var(--c-grey-dark);
    }

    &__answer {
        color: var(--c-grey-dark);
        margin-top: 1rem;
    }

    &__empty-field {
        color: var(--c-grey);
    }
}
