@use "src/styles" as *;

.vacancy-overview-skeletons {

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__card {
        padding: 2rem;
    }

    &__header {
        display: flex;
    }

    &__logo {
        @include square(4.5rem);
    }

    &__title-wrapper {
        width: 80%;
        margin-left: 1.5rem;
    }

    &__title {
        height: 1.125rem;
    }

    &__meta-wrapper {
        max-width: 14rem;
        margin-top: .75rem;
    }

    &__publish-date {
        height: .875rem;
        margin-top: .5rem;
    }

    &__property-list {
        display: flex;
        margin-top: 1rem;
        padding: 0;
    }

    &__property {
        display: block;
        width: 4rem;
        height: 2.25rem;
        padding: .5rem .75rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);

        & + & {
            margin-left: 1rem;
        }
    }
}
