@use "src/styles" as *;

$component: "job-experience-form";

.#{$component} {

    & > * + * {
        margin-top: 1.5rem;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        .#{$component}__input + .#{$component}__input {
            margin-top: 1.5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__input {
        width: 100%;
    }

    &__checkbox {
        width: auto;
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
