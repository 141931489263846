@use "src/styles" as *;

.skills-passport-actions-card {

    &__title {
        font-size: 1.125rem;
    }

    &__action-navigation-wrapper {
        position: relative;
        margin-top: 1rem;
    }

    &__action-navigation-button {
        @extend %button--secondary;
    }

    &__action-navigation {
        top: calc(100% + .5rem);
        left: 0;
        z-index: 10;
        width: 20rem;

        @include for-size(tablet-landscape-up) {
            left: initial;
            right: 0;
        }

        @include for-size(desktop-up) {
            left: 0;
            right: initial;
        }
    }

    &__button {
        @extend %button--secondary;

        align-self: flex-start;
        margin-top: 1rem;
    }
}
