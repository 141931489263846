.logout-page {

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        width: auto;
        height: 3rem;
    }

    &__illustration {
        margin-top: 2rem;
    }

    &__message {
        max-width: 40rem;
        margin-top: 1.5rem;
        padding-top: 2.5rem;
    }
}
