@use "src/styles" as *;

.competency-tag {

    &__button {
        --c-competency-tag: var(--c-primary);
        --c-competency-tag-hover: rgba(var(--c-primary-rgb), .75);

        @extend %button--secondary;

        padding: .5rem;
        font-size: .875rem;
        font-weight: var(--fw-normal);
        color: var(--c-always-black);
        background-color: var(--c-competency-tag);
        border-color: var(--c-primary);

        &:enabled:hover,
        &:enabled:focus {
            background-color: var(--c-competency-tag-hover);
        }

        &--is-regular-level {
            --c-competency-tag: rgba(var(--c-primary-rgb), .7);
            --c-competency-tag-hover: rgba(var(--c-primary-rgb), .6);
        }

        &--is-mediocre-level {
            --c-competency-tag: rgba(var(--c-primary-rgb), .4);
            --c-competency-tag-hover: rgba(var(--c-primary-rgb), .3);
        }

        &--is-low-level {
            --c-competency-tag: rgba(var(--c-primary-rgb), .1);
            --c-competency-tag-hover: rgba(var(--c-primary-rgb), .05);
        }
    }

    &__tips-title {
        margin: 0;
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__dialog-tip {
        margin-top: .5rem;
        color: var(--c-grey-dark);
    }

    &__close-dialog-button {
        @extend %button--textual;

        margin-top: 2rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey);
    }
}
