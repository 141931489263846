@use "src/styles" as *;

.profile-navigation {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    color: currentColor;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        padding: .5rem 1.25rem .5rem 2rem;
    }

    &__user-name {
        word-break: break-word;
        font-weight: var(--fw-medium);
    }

    &__loading-spinner {
        color: var(--c-primary);
    }

    &__toggle-button {
        @extend %button--no-background;

        margin-right: 1rem;
        padding: .5rem;
        color: currentColor;

        @include for-size(tablet-landscape-up) {
            margin-left: 1rem;
            margin-right: 0;
            padding: 1rem;
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__nav {
        top: calc(100% + .5rem);
        left: 0;
        z-index: 10;

        @include for-size(tablet-landscape-up) {
            left: initial;
            right: 0;
        }
    }
}
