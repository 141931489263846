@use "src/styles" as *;

.notice {
    display: inline-flex;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius);

    &--is-error {
        background-color: rgba(var(--c-negative-rgb), .3);
    }

    &--is-regular {
        background-color: var(--c-grey-light);
    }

    &--is-warning {
        background-color: rgba(var(--c-warning-rgb), .3);
    }

    &__icon {
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.25rem;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__text {
        margin-left: 1rem;
        color: var(--c-black);

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__action-button {
        margin: 1rem 0 0 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
        }
    }
}
