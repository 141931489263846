.counselor-step {

    &__input {
        margin-top: 1.5rem;
    }

    &__loader {
        margin-top: 1.5rem;
    }

    &__result-list {
        margin: 1.5rem 0 0;
        padding: 0;
        overflow-y: auto;
        border: var(--line);
    }

    &__result-item {

        & + & {
            border-top: var(--line);
        }
    }
}
