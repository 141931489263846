.job-experiences-card {

    &__loader,
    &__null-state {
        margin-top: 2rem;
    }

    &__job-wrapper {
        padding: 0;
        margin: 2rem 0 0 0;
    }
}
