@use "src/styles" as *;

.internal-occupation-detail-header {
    max-width: 70rem;

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__name {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: .5rem;
        white-space: pre-wrap;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &__list-item {
        padding: .5rem;
        list-style-type: none;
        font-size: .875rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }

    &__matched-skills {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
        border-top: var(--line);

        @include for-size(tablet-portrait-up) {
            margin: 0 0 0 2rem;
            padding: 0 0 0 2rem;
            border-top: none;
            border-left: var(--line);
        }
    }

    &__progress-circle {
        @include circle(6rem);
    }
}
