@use "src/styles" as *;

.competency-talent-selector {
    position: relative;

    &__result-list {
        max-height: 22em;
        margin: 0;
        padding: 0;
        overflow: auto;
        border: 1px solid var(--c-grey-light);
        border-top: none;
    }

    &__competency-list {
        padding: 0;
        margin: 0;
    }

    &__talent-level {
        display: none;
        font-size: .875rem;
        text-transform: uppercase;
        margin-top: 1rem;
        margin-left: 1rem;
        color: var(--c-grey);

        @include for-size(phone-only) {
            display: block;
        }

        &--talented {
            color: var(--c-primary);
        }

        &--less-talented {
            color: var(--c-warning);
        }
    }
}
