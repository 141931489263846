.organisation-detail-occupation-skeletons {

    &__breadcrumbs {
        max-width: 20rem;
        height: 1rem;
    }

    &__header-card {
        display: flex;
        justify-content: space-between;
        margin-top: 2.5rem;
    }

    &__content-wrapper {
        width: 100%;
    }

    &__title {
        max-width: 25rem;
        height: 1.5rem;
    }

    &__description {
        max-width: 45rem;
    }

    &__action-buttons {
        display: flex;
    }

    &__action-button {
        width: 3.875rem;
        height: 3.125rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
        
        & + & {
            margin-left: 1rem;
        }
    }

    &__list {
        margin: 1.5rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {
        
        & + & {
            margin-top: .5rem;
        }
    }

    &__card {
        margin-top: 1rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__small-title {
        max-width: 15rem;
        height: 1.25rem;
    }

    &__add-button {
        width: 16rem;
        height: 3.125rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }

    &__skill-list {
        display: flex;
        flex-wrap: wrap;
        max-width: var(--paragraph-max-width);
        gap: 1rem;
        margin: 1rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__skill-list-item {
        width: 8rem;
        height: 2.25rem;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }
}
