.organisation-detail-tabs {

    &__skeletons,
    &__tabs-navigation {
        margin-top: 1rem;
    }

    &__tab {
        max-width: 75rem;
        margin-top: 2rem;
    }
}
