@use "src/styles" as *;

.adding-card {
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;

        & + * {
            margin-top: 2rem;
        }
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__fill-label {
        margin-left: .5rem;
    }

    &__add-button {
        @extend %button--secondary;

        margin-left: 1rem;
    }

    &__description {
        margin-top: 1rem;
        padding-bottom: 1.5rem;
        color: var(--c-grey-dark);
        border-bottom: var(--line);
    }
}
