@use "src/styles" as *;

.page {
    --page-viewport-height: 100%;
    --page-menu-width: 15rem;

    position: relative;
    z-index: 10;
    width: 100%;
    min-height: 100vh;
    padding: var(--s-page-padding);
    overflow-x: hidden;
    background-color: var(--c-background);

    @include for-size(tablet-landscape-up) {
        padding: var(--s-page-padding);
    }

    &--has-navigation {
        padding-top: 0;

        @include for-size(tablet-landscape-up) {
            padding: var(--s-page-padding);
            padding-left: calc(#{var(--page-menu-width)} + #{var(--s-page-padding)});
        }
    }

    &__navigation-header {
        position: relative;
        z-index: 30;
        width: calc(100% + (#{var(--s-page-padding)} * 2));
        transform: translateX(negative(var(--s-page-padding)));

        @include for-size(tablet-landscape-up) {
            width: 100%;
            transform: none;
        }
    }
}
