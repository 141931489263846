@use "src/styles" as *;

.searchable-competency-option {
    list-style-type: none;

    border-bottom: var(--line);

    &__button {
        @extend %button--no-background;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        text-align: left;
        color: var(--c-grey-dark);
        border-radius: 0;

        &:enabled:hover,
        &:enabled:focus {
            background-color: var(--c-grey-light);
        }
    }

    &__secondary-label {
        font-size: .875rem;
        text-transform: uppercase;
        color: var(--c-grey);

        @include for-size(phone-only) {
            display: none;
        }

        &--talented {
            color: var(--c-primary);
        }

        &--less-talented {
            color: var(--c-warning);
        }
    }

    &__icon {
        @include circle(.8rem);

        display: none;
        position: relative;
        margin-left: .5rem;
        border: 2px solid var(--c-white);
        background-color: var(--c-grey);

        @include for-size(phone-only) {
            display: block;
        }

        &--talented {
            background-color: var(--c-primary);
        }

        &--less-talented {
            background-color: var(--c-warning);
        }

        &--default-competency {
            display: none;
        }
    }
}
