.range-input-bar {
    position: relative;
    width: 100%;
    height: .5rem;
    cursor: pointer;
    background-color: var(--c-grey-light);

    &--is-disabled {
        pointer-events: none;
    }

    &__track {
        --range-input-track-width: calc(var(--range-input-width) * var(--range-input-track-size));

        position: relative;
        z-index: 10;
        width: var(--range-input-track-width);
        height: 100%;
        background-color: var(--c-primary);
        transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-min)));

        &--is-inverted {
            width: calc(var(--range-input-width) - var(--range-input-track-width));
            transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-max)));
        }
    }
}
