@use "src/styles" as *;

.take-analysis-skeletons {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
    }

    &__description-card {
        width: 100%;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            order: -1;
            margin-top: 0;
        }
    }

    &__paragraph-line {
        margin-top: 2rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__button {
        margin-top: 2rem;
        width: 20rem;
        height: 3rem;
    }

    &__logo-card {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: auto;
            margin-left: 1.5rem;
        }
    }
}
