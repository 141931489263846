@use "src/styles" as *;

.candidate-exchange {
    @extend %flex-align-center;

    flex-direction: column;
    padding-top: 2rem;
    border-top: var(--line);

    &__loading-spinner {
        @include circle(3rem);

        color: var(--c-primary);
    }

    &__success {
        @extend %flex-align-center;
        @include circle(3rem);

        background-color: var(--c-primary);
    }

    &__success-icon {
        color: var(--c-always-white);
    }

    &__result-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center
    }

    &__error {
        @extend %flex-align-center;
        @include circle(3rem);

        background-color: var(--c-negative);
    }

    &__error-icon {
        color: var(--c-always-white);
        height: 1.5rem;
    }

    &__success-message,
    &__error-label {
        margin-top: 1.5rem;
        text-align: center;
    }

    &__action-button {
        @extend %button--textual;

        margin-top: 1.5rem;
    }
}
