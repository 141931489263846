.organisation-detail-modules-skeletons {

    &__title {
        max-width: 9rem;
        height: 1.5rem;
    }
 
    &__module {
        max-width: 24rem;
        display: flex;
        margin-top: 2rem;
    }

    &__module-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__label {
        height: 1.125rem;
    }

    &__description {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__toggle {
        width: 3.5rem;
        height: 1.5rem;
        align-self: center;
        border: 1px solid var(--c-grey);
        border-radius: var(--border-radius);
    }
}
