@use "src/styles" as *;

.skills-form {

    &__input-wrapper {

        & + & {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: var(--line);
        }
    }

    &__input-title {
        font-size: 1.125rem;
    }

    &__collapse-button {
        @extend %button--no-background;

        width: 100%;
        justify-content: space-between;
        padding-left: 0;
        color: var(--c-black);
    }

    &__input-description {
        margin-top: 1rem;
    }

    &__input,
    &__suggested-skills {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
