@use "src/styles" as *;

.development-plan-notes {

    &__header {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row-reverse;
        }
    }

    &__null-state {
        padding-left: 1.5rem;
        border-left: .25rem solid var(--c-grey-light);
    }

    &__list {
        margin: 1rem 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {

        & + & {
            margin-top: 1rem;
        }
    }
}
