@use "src/styles" as *;

$component: "pagination-input";

.#{$component} {

    &__radio {
        position: absolute;
        opacity: 0;

        &:focus ~ .#{$component}__label {
            transform: scale(1.2);
        }

        &:checked ~ .#{$component}__label {
            color: var(--c-always-white);
            background-color: var(--c-primary);
        }
    }

    &__label {
        @extend %flex-align-center;

        width: var(--pagination-block-size);
        height: var(--pagination-block-size);
        font-weight: var(--fw-medium);
        cursor: pointer;
        border-radius: var(--border-radius);

        &:hover {
            color: var(--c-primary);
        }
    }
}
