@use "src/styles" as *;

.development-header-skeletons {

    &__button {
        width: 13rem;
        height: 3rem;
        margin-left: 0;

        @include for-size(tablet-landscape-up) {
            margin-left: auto;
        }
    }
}
