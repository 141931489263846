.exchange-success-message {

    &__paragraph {

        & + & {
            margin-top: 1rem;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 2rem;
    }
}
