.organisation-detail-occupation-overview {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 1.125rem;
    }

    &__null-state-card {
        margin-top: 2rem;
    }

    &__list {
        margin: 2rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {

        & + & {
            margin-top: 1rem;
        }
    }

    &__pagination {
        margin-top: 3rem;
    }
}
