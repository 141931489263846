@use "src/styles" as *;

.table-cell {
    @include column-width(10rem);

    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;

    &--align-left {
        justify-content: flex-start;
        text-align: left;
    }

    &--align-center {
        justify-content: center;
        text-align: center;
    }

    &--align-right {
        justify-content: flex-end;
        text-align: right;
    }

    &__content-wrapper {
        max-height: 100%;
        padding-right: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
