.organisation-detail-modules {

    &__header {
        display: flex;
        align-items: center;
    }
    
    &__loader {
        --loading-spinner-size: 1.25rem;

        color: var(--c-primary);
        margin-left: auto;
    }

    &__confirmation {
        margin-left: auto;
        font-size: .875rem;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__list {
        margin: 2rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {
        max-width: 24rem;

        & + & {
            margin-top: 1rem;
        }
    }
}
