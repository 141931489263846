@font-face {
    font-display: swap;
    font-family: 'Geomanist';
    font-weight: 400;
    src: url('./typography-library/geomanist-regular-webfont.woff2') format('woff2'),
         url('./typography-library/geomanist-regular-webfont.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Geomanist';
    font-weight: 500;
    src: url('./typography-library/geomanist-medium-webfont.woff2') format('woff2'),
         url('./typography-library/geomanist-medium-webfont.woff') format('woff');
}
