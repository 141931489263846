@use "src/styles" as *;

.matching-skills-filter-form {

    &__title {
        font-size: 1.5rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__input-wrapper {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: var(--line);
    }

    &__collapse-button {
        @extend %button--no-background;

        width: 100%;
        justify-content: space-between;
        padding-left: 0;
        color: var(--c-black);
    }

    &__input-description {
        margin-top: 1rem;
    }

    &__button-wrapper {
        margin-top: 2.5rem;
    }

    &__input,
    &__suggested-skills {
        margin-top: 1.5rem;
    }

    &__cancel-button {
        @extend %button--transparent-background;

        color: var(--c-grey-dark);
    }
}
