@use "src/styles" as *;

.vacancy-detail-body {

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }

    &__card-title {
        font-size: 1.125rem;
    }

    &__card-description {
        margin-top: 1rem;
    }

    &__about-description {
        @extend %html-format;

        margin-top: 1rem;
    }

    &__tag-list {
        margin-top: 1.5rem;

        & + & {
            margin-top: 2.5rem;
        }
    }
}
