@use "src/styles" as *;

.block-radio-item {
    position: relative;
    width: 100%;
    padding: 1rem;
    overflow: hidden;
    font-weight: var(--fw-medium);
    text-align: center;
    color: var(--c-grey-dark);
    cursor: pointer;
    border: 1px solid var(--c-grey-light);

    &::after {
        @extend %flex-align-center;
        @extend %click-extend;

        content: attr(data-label);
        color: var(--c-white);
        background-color: var(--c-grey-dark);
        opacity: 0;
        transition: opacity .1s ease;
    }

    &:focus-within,
    &--is-checked {

        &::after {
            opacity: 1;
        }
    }

    &--is-disabled {
        cursor: not-allowed;
        background-color: var(--c-grey-light);

        &::after {
            color: var(--c-white);
            background-color: var(--c-grey);
        }
    }

    & + & {
        border-left: none;
    }

    &:first-child {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    &:last-child {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    &__input {
        position: absolute;
        opacity: 0;
    }
}
