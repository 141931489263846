@use "src/styles" as *;

.occupation-detail-header-skeletons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__text {
        width: 100%;
    }

    &__name {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__alternative-names-label {
        max-width: 10rem;
        margin-top: 2rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &__list-item {
        height: 2.125rem;
        width: 6.5rem;
        list-style-type: none;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }

    &__matched-skills {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
        border-top: var(--line);

        @include for-size(tablet-portrait-up) {
            margin: 0 0 0 2rem;
            padding: 0 0 0 2rem;
            width: auto;
            border-top: none;
            border-left: var(--line);
        }
    }

    &__progress-circle {
        @include circle(6rem);

        border: 1px solid var(--c-grey-light);
    }

    &__progress-description {
        width: 17.5rem;
        margin-top: 1rem;
    }

    &__create-development-plan {
        width: 12rem;
        height: 3rem;
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 2rem;
        }
    }
}
