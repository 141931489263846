@use "src/styles" as *;

.manage-analyses {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
    }

    &__description-card {
        width: 100%;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            order: -1;
            margin-top: 0;
        }
    }

    &__logo-card {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: auto;
            margin-left: 1.5rem;
        }
    }

    &__description-title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__description-text {
        margin: 2rem 0 0;

        p {
            margin-top: 1rem;
        }
    }

    &__action-button {
        margin-top: 2rem;
    }
}
