.talents-and-incentives-tab {

    &__card {
        margin-top: 1.5rem;
        max-width: 60rem;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__intro {
        margin-top: 1rem;
    }
}
