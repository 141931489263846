@use "src/styles" as *;

.candidate-illustration {
    --candidate-illustration-animation-duration: 5s;
    --candidate-illustration-c-hair: #212b36;
    --candidate-illustration-c-darkmode-hair: #dfbd7a;
    --candidate-illustration-c-skin: #e49d75;
    --candidate-illustration-c-skin-dark: #d18c65;
    --candidate-illustration-c-shirt: #ffd98e;
    --candidate-illustration-c-shirt-light: #ffe4ad;
    --candidate-illustration-c-shirt-dark: #ffc78e;
    --candidate-illustration-c-notification-icon: #ffffff;

    --candidate-illustration-c-tma-lime: #afcc46;
    --candidate-illustration-c-tma-red: #ed7352;
    --candidate-illustration-c-tma-yellow: #fab400;
    --candidate-illustration-c-tma-purple: #d24291;
    --candidate-illustration-c-tma-blue: #0086c8;
    --candidate-illustration-c-tma-green: #50b47e;
    --candidate-illustration-c-tma-grey: #9d9d9d;

    --candidate-illustration-c-talentz-yellow: #ffdb02;
    --candidate-illustration-c-talentz-black: #242424;

    &__character {
        height: 6rem;

        @include for-size(tablet-landscape-up) {
            height: 9rem;
        }
    }

    &__neck-joint {
        @extend %transforming-svg;
        animation: candidate-illustration-neck ease alternate infinite;
        animation-duration: var(--candidate-illustration-animation-duration);
    }

    &__left-shoulder-joint {
        @extend %transforming-svg;
        animation: candidate-illustration-shoulder ease alternate infinite;
        animation-duration: var(--candidate-illustration-animation-duration);
    }

    &__left-elbow-joint {
        @extend %transforming-svg;
        animation: candidate-illustration-elbow ease alternate infinite;
        animation-duration: var(--candidate-illustration-animation-duration);
    }

    &__left-wrist-joint {
        @extend %transforming-svg;
        animation: candidate-illustration-wrist ease alternate infinite;
        animation-duration: var(--candidate-illustration-animation-duration);
    }

    &__notification-joint {
        @extend %transforming-svg;
        animation: candidate-illustration-notification ease alternate infinite;
        animation-duration: var(--candidate-illustration-animation-duration);
    }

    &__joint-handle {
        fill: none;
        stroke: none;
    }

    &__hair {
        fill: var(--candidate-illustration-c-hair);

        @media (prefers-color-scheme: dark) {
            fill: var(--candidate-illustration-c-darkmode-hair);
        }
    }

    &__face,
    &__left-upper-arm,
    &__left-lower-arm,
    &__left-hand {
        fill: var(--candidate-illustration-c-skin);
    }

    &__neck,
    &__right-arm {
        fill: var(--candidate-illustration-c-skin-dark);
    }

    &__right-sleeve {
        fill: var(--candidate-illustration-c-shirt-dark);
    }

    &__shirt {
        fill: var(--candidate-illustration-c-shirt);
    }

    &__left-sleeve {
        fill: var(--candidate-illustration-c-shirt-light);
    }

    &__notification-balloon {
        fill: var(--c-secondary);

        &--is-skills-passport {
            fill: var(--c-notification);
        }

        &--is-matching {
            fill: var(--c-warning);
        }
    }

    &__skills-passport-icon,
    &__education-icon,
    &__matching-icon,
    &__personality-description-icon,
    &__competency-icon,
    &__workplace-preferences-icon {
        @extend %transforming-svg;

        fill: var(--candidate-illustration-c-notification-icon);
        transform: scale(0);
        transition: transform .1s ease;

        &--is-active {
            transform: scale(1);
        }
    }

    &__regular-notification,
    &__tma-notification,
    &__talentz-notification {
        @extend %transforming-svg;

        transform: scale(0);
        transform-origin: center bottom;
        transition: transform .3s ease;

        &--is-active {
            transform: scale(1);
        }
    }

    &__tma-balloon {
        fill: var(--c-white);
        stroke: var(--c-primary);
        stroke-width: 5;
    }

    &__tma-circle-lime { fill: var(--candidate-illustration-c-tma-lime); }
    &__tma-circle-red { fill: var(--candidate-illustration-c-tma-red); }
    &__tma-circle-yellow { fill: var(--candidate-illustration-c-tma-yellow); }
    &__tma-circle-purple { fill: var(--candidate-illustration-c-tma-purple); }
    &__tma-circle-blue { fill: var(--candidate-illustration-c-tma-blue); }
    &__tma-circle-green { fill: var(--candidate-illustration-c-tma-green); }
    &__tma-text { fill: var(--candidate-illustration-c-tma-grey); }

    &__talentz-balloon {
        fill: var(--candidate-illustration-c-talentz-yellow);
    }

    &__talentz-text {
        fill: var(--candidate-illustration-c-talentz-black);
    }

    &__phone {
        fill: var(--c-always-black);
    }

    &__background {
        fill: var(--c-grey-light);
    }
}
