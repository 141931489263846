.vacancy-overview {

    &__null-state {
        width: 100%;
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__list-item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__pagination {
        margin-top: 3rem;
    }
}
