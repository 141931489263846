.environment-logo {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: right;

    &__logo {
        height: 2rem;
        min-height: 2rem;
    }

    &__underline {
        font-size: .625rem;
        font-weight: var(--fw-medium);
        line-height: 1;
        transform: translateY(.25rem);
    }
}
