@use "src/styles" as *;

.development-overview-null-state {
    @extend %flex-align-center;

    flex-direction: column;
    padding: 4rem 2rem;

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(4rem);

        background-color: var(--c-grey-light);
    }

    &__icon {
        height: 1.125rem;
        color: var(--c-grey);
    }

    &__title {
        margin-top: 2rem;
        color: var(--c-grey-dark);
        font-size: 1rem;
    }

    &__description {
        margin-top: .5rem;
    }

    &__action-buttons {
        @extend %flex-align-center;

        margin-top: 2rem;
    }

    &__button {
        @extend %button--transparent-background;

        color: var(--c-grey-dark);
        border: 1px solid var(--c-grey-light);

        &::after {
            background-color: var(--c-white);
        }

        & + & {
            margin-left: 1rem;
        }
    }
}
