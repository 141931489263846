@use "src/styles" as *;

.school-experience-item {
    display: flex;

    &__content-wrapper {
        flex: 1;
    }

    &__controls-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__field-of-study {
        color: var(--c-grey-dark);
    }

    &__school {
        text-transform: none;
        color: var(--c-grey);

        &::before {
            content: " - ";
        }
    }

    &__dates {
        color: var(--c-grey);
    }

    &__description {
        margin-top: 1rem;
    }

    &__control-button {
        @include circle(3rem);

        padding: 1rem;
        color: var(--c-grey-dark);
        background: none;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }

        & + & {
            margin-top: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: .5rem;
            }
        }

        &--is-delete {

            &:hover,
            &:focus {
                color: var(--c-negative);
                background-color: rgba(var(--c-negative-rgb), .2);
            }
        }
    }
}
