@use "src/styles" as *;

.education-overview-skeletons {
    padding: 0;

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        padding: 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__logo {
        @include square(3.5rem);
    }

    &__content-wrapper {
        flex: 1;
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }

    &__header {
        width: 50%;
    }

    &__title {
        height: 1.25rem;
    }

    &__educator {
        margin-top: .5rem;
    }

    &__meta-wrapper {
        max-width: 14rem;
        margin-top: .75rem;
    }

    &__property-list {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        padding: 0;
    }

    &__property {
        display: block;
        width: 4rem;
        height: 2.25rem;
        padding: .5rem .75rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);
    }

    &__description {
        width: 100%;
        margin-top: 1.5rem;
    }

    &__description-line {

        & + & {
            margin-top: .5rem;
        }
    }
}
