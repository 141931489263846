@use "src/styles" as *;

.searchable-value-input {
    position: relative;

    &__loading-wrapper {
        @extend %flex-align-center;

        position: absolute;
        z-index: 30;
        width: 100%;
        margin: 0;
        padding: 2rem 0;
        background-color: var(--c-white);
        border: 1px solid var(--c-grey-light);
        border-top: none;
    }

    &__result-list {
        max-height: 22em;
        margin: 0;
        padding: 0;
        overflow: auto;
        border: 1px solid var(--c-grey-light);
        border-top: none;
    }
}
