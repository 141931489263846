@use "src/styles" as *;

.candidate-personal-data-form {

    &__loader {
        margin-top: 2rem;
    }

    &__row {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }

        & + & {
            margin-top: 1rem;
        }
    }

    &__column {
        width: 100%;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }

    &__input {
        display: block;
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__button-wrapper {
        justify-content: space-between;
        margin-top: 2rem;
    }

    &__button {

        &--logout {
            @extend %button--transparent-background;

            color: var(--c-grey-dark);
        }
    }
}
