@use "src/styles" as *;

.education-search-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__input + &__input,
    &__submit-button {
        margin-top: 1.5rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__input-field,
    &__submit-button {

        @include for-size(tablet-portrait-up) {
            height: 3.5rem;
        }
    }

    &__input {
        width: 100%;
    }
}
