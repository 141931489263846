.education-levels-filter {

    &__label {
        margin-bottom: .75rem;
    }

    &__item {

        & + & {
            margin-top: 1rem;
        }
    }
}
