@use "src/styles" as *;

.occupation-overview-skeletons {

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__title {
        max-width: 15rem;
        font-size: 1.25rem;
    }

    &__view-selector {
        width: 100%;
        height: 3rem;
        margin-top: 1rem;
        margin-left: auto;

        @include for-size(tablet-portrait-up) {
            max-width: 25rem;
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__list {
        max-width: 58rem;
        margin: 2rem 0 0 0;
        padding: 0;
    }

    &__list-item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__text {
        width: 100%;
    }

    &__name {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__matched-skills {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
        border-top: var(--line);

        @include for-size(tablet-portrait-up) {
            margin: 0 0 0 2rem;
            padding: 0 0 0 2rem;
            width: auto;
            border-top: none;
            border-left: var(--line);
        }
    }

    &__progress-circle {
        @include circle(6rem);

        border: 1px solid var(--c-grey-light);
    }
}
