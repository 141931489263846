@use "src/styles" as *;

$component: "organisation-table";

.#{$component} {
    --sticky-column-width: 10rem;

    padding: 2rem;

    &__header {
        display: flex;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__loader {
        --loading-spinner-size: 1.25rem;

        color: var(--c-primary);
        margin-left: auto;
    }

    &__null-state-wrapper,
    &__null-state-button,
    &__error-message {
        margin-top: 1.5rem;
    }

    &__table {
        width: calc(100% + 4rem);
        transform: translateX(-2rem);
        margin-top: 2rem;
    }

    &__header-row {
        padding: 0 0 0 2rem;
        color: var(--c-grey);
        font-weight: var(--fw-medium);
    }

    &__row {
        position: relative;
        padding: 0 0 0 2rem;

        &:hover,
        &:focus-within {
            background-color: rgba(var(--c-primary-rgb), .05);

            .#{$component}__fixed-cell {
                background-color: var(--c-white);
            }
        }
    }

    &__cell {
        @include column-width(16rem);

        &:nth-of-type(2) { @include column-width(14rem); }
        &:nth-of-type(3) { @include column-width(11rem); }
        &:nth-of-type(4) { @include column-width(12rem); }
        &:nth-of-type(5) { @include column-width(21rem); }

        &--is-name {
            color: var(--c-black);
            font-weight: var(--fw-medium);
        }
    }

    &__fixed-cell {
        @include column-width(var(--sticky-column-width));

        justify-content: center;
        position: sticky;
        z-index: 20;
        right: 0;
        background-color: var(--c-white);
    }

    &__fixed-cell-content {
        padding: 0 1rem;
    }

    &__action-button {
        display: inline-flex;
        width: 2rem;
        padding: .5rem;
        color: var(--c-grey-dark);
        background: none;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }

        &:disabled {
            background-color: transparent;
        }

        & + & {
            margin-left: 1rem;
        }

        &--is-delete {

            &:hover,
            &:focus {
                color: var(--c-negative);
                background-color: rgba(var(--c-negative-rgb), .2);
            }
        }
    }

    &__hidden-cell {
        @include column-width(0);
    }

    &__redirect-button {
        @extend %button--no-background;
        @extend %click-extend;

        z-index: 10;
    }
}
