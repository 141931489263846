.occupation-overview-no-skills {
    padding-left: 1.5rem;
    border-left: .25rem solid var(--c-grey-light);

    &__paragraph {

        & + & {
            margin-top: .5rem;
        }
    }

    &__button {
        margin-top: 1rem;
    }
}
