@use "src/styles" as *;

.internal-occupation-detail-header-skeletons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__text {
        width: 100%;
    }

    &__name {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__matched-skills {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
        border-top: var(--line);

        @include for-size(tablet-portrait-up) {
            margin: 0 0 0 2rem;
            padding: 0 0 0 2rem;
            width: auto;
            border-top: none;
            border-left: var(--line);
        }
    }

    &__progress-circle {
        @include circle(6rem);

        border: 1px solid var(--c-grey-light);
    }

    &__progress-description {
        width: 17.5rem;
        margin-top: 1rem;
    }
}
