.view-selector {
    display: flex;
    align-items: center;

    &:focus-within &__option-list {
        box-shadow: var(--focus-ring);
    }

    &__label {
        margin-right: 1rem;
    }

    &__option-list {
        display: flex;
        overflow: hidden;
        border-radius: var(--border-radius);
    }
}
