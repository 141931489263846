@use "src/styles" as *;

.pagination-button {
    @extend %flex-align-center;

    width: var(--pagination-block-size);
    height: var(--pagination-block-size);
    padding: 0;

    &:first-of-type {
        margin-right: 1rem;
    }

    &:last-of-type {
        margin-left: 1rem;
    }
}
