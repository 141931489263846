@use "src/styles" as *;

.textarea-input {
    @extend %input;

    min-height: 6rem;
    resize: vertical;

    &--has-error {
        border: 2px solid var(--c-negative);
    }
}
