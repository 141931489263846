@use "src/styles" as *;

.seek-vacancies-page {

    &__overview-title {
        min-height: 2rem;
        margin-top: 2rem;
        font-size: 1.5rem;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__matching-filter {

        @include for-size(tablet-landscape-up) {
            max-width: 28rem;
        }
    }

    &__vacancy-overview {
        width: 100%;
        max-width: 58rem;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }
}
