@use "src/styles" as *;

.counselor-table {
    --sticky-column-width: 4rem;

    padding: 2rem;

    &__header {
        display: flex;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__loader {
        --loading-spinner-size: 1.25rem;

        color: var(--c-primary);
        margin-left: auto;
    }

    &__null-state-wrapper,
    &__null-state-button,
    &__error-message {
        margin-top: 1.5rem;
    }

    &__table {
        width: calc(100% + 4rem);
        transform: translateX(-2rem);
        margin-top: 2rem;
    }

    &__header-row {
        padding: 0 0 0 2rem;
        color: var(--c-grey);
        font-weight: var(--fw-medium);
    }

    &__row {
        position: relative;
        padding: 0 0 0 2rem;
    }

    &__cell {
        @include column-width(14rem);

        &:nth-of-type(2) { @include column-width(16rem); }
        &:nth-of-type(3) { @include column-width(17rem); }

        &--is-name {
            color: var(--c-black);
            font-weight: var(--fw-medium);
        }
    }

    &__fixed-cell {
        @include column-width(var(--sticky-column-width));

        justify-content: center;
        position: sticky;
        z-index: 20;
        right: 0;
        background-color: var(--c-white);
    }

    &__fixed-cell-content {
        padding: 0 1rem;
    }

    &__action-button {
        display: inline-flex;
        width: 2rem;
        padding: .5rem;
        color: var(--c-grey-dark);
        background: none;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }

        & + & {
            margin-left: 1rem;
        }
    }
}
