$component: "navigation-item";

.#{$component} {
    list-style-type: none;

    & + & {
        margin-top: 1rem;
    }

    &__link {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        font-weight: var(--fw-medium);
        text-decoration: none;
        color: var(--c-grey);

        &--is-active {
            color: var(--c-grey-dark);
            background-color: var(--c-background);

            .#{$component}__link-icon {
                color: var(--c-primary);
            }
        }

        &:hover,
        &:focus {
            color: var(--c-grey-dark);
            background-color: var(--c-grey-light);
        }
    }

    &__link-icon {
        height: 1.125rem;
        margin-right: 1.25rem;
    }
}
