@use "src/styles" as *;

.counselor-dashboard {

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__refresh-button {
        @extend %button--secondary;

        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__candidate-table {
        margin-top: 2.5rem;
    }

    &__pagination {
        margin-top: 1rem;
    }
}
