@use "src/styles" as *;

$component: "submit-button";

.#{$component} {
    @extend %button;

    display: flex;
    align-items: center;

    &--align-right {
        flex-direction: row-reverse;

        .#{$component}__icon {
            margin-right: 0;
            margin-left: .625rem;
        }
    }

    &--hidden-label {

        .#{$component}__icon {
            margin: 0;
        }
    }

    &__icon {
        @include square(1em);

        display: block;
        margin-right: .625rem;
    }
}
