.organisation-occupation-form {

    &__input {

        & + & {
            margin-top: 1rem;
        }
    }

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
