@use "src/styles" as *;

$component: "descriptive-radio-item";

.#{$component} {
    width: 100%;
    position: relative;
    padding: 1rem;
    cursor: pointer;
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);

    &--is-disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &__label-wrapper {
        display: flex;
        align-items: center;
        color: var(--c-grey-dark);
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus ~ .#{$component}__box {
            box-shadow: var(--focus-ring);
        }

        &:checked ~ .#{$component}__box {

            &::after {
                opacity: 1;
                transform: scale(.625);
            }
        }
    }

    &__box {
        @include circle(1.5rem);

        position: relative;
        border: 1px solid currentColor;

        &::after {
            @extend %click-extend;

            content: "";
            border-radius: inherit;
            background-color: currentColor;
            opacity: 0;
            transform: scale(0);
            transition: opacity .1s ease, transform .1s ease;
        }
    }

    &__label {
        margin-left: 1rem;
        font-weight: var(--fw-medium);
        user-select: none;
    }

    &__description {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: var(--line);
    }
}
