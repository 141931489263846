@use "src/styles" as *;

.tabs-navigation-item {
    list-style-type: none;
    margin-bottom: -2px;

    & + & {
        margin-left: 2rem;
    }

    &__link {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 0 .5rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        text-decoration: none;
        color: var(--c-grey-dark);

        &--is-active {
            border-bottom: solid 3px var(--c-primary);
            color: var(--c-black);
        }

        &:hover,
        &:focus {
            color: var(--c-black);
        }
    }

    &__indicator {
        @include circle(.5rem);

        position: relative;
        top: -.5rem;
        background-color: var(--c-warning);
    }
}
