@use "src/styles" as *;

.education-filters {

    &__card {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__search-form {

        @include for-size(tablet-landscape-up) {
            max-width: 52rem;
        }
    }

    &__filter-wrapper {
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }
}
