.leaving-platform-dialog {
    text-align: center;

    &__title {
        margin-top: 2rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__link-button {
        margin-top: 2rem;
    }
}
