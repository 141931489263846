@use "src/styles" as *;

.incentive {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__name {
        margin: 0;
        font-weight: var(--fw-medium);
    }

    &__description {
        margin-top: .5rem;
    }

    &__slider {
        margin: 1rem 0 0;

        @include for-size(tablet-portrait-up) {
            margin: 0 0 0 2rem;
        }
    }
}
