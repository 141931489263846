.stap-label {
    display: inline-flex;
    align-items: center;
    padding: .5rem;
    color: var(--c-black);
    border-radius: 2rem;
    border: 1px solid var(--c-primary);

    &__icon {
        height: 1.25rem;
        color: var(--c-primary);
    }

    &__icon-accent {
        color: var(--c-always-white);
    }

    &__label {
        margin-left: .5rem;
    }
}
