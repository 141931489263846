@use "src/styles" as *;

$component: "candidate-table";

.#{$component} {
    padding-left: 0;
    padding-right: 0;

    &__header {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__header-state-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
    }

    &__loader {
        --loading-spinner-size: 1.25rem;

        color: var(--c-primary);
        margin-left: auto;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__search-input {
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }

    &__null-state-wrapper {
        margin-top: 1.5rem;
        padding: 0 2rem;
    }

    &__null-state-button {
        margin-top: 1.5rem;
    }

    &__error-wrapper {
        display: flex;
        justify-content: center;
        padding: 0 2rem;
    }

    &__error-message {
        margin-top: 1rem;
    }

    &__table {
        margin-top: 1.5rem;
    }

    &__row {
        position: relative;
        padding-left: 2rem;

        &--is-interactive {

            &:hover,
            &:focus-within {
                background-color: rgba(var(--c-primary-rgb), .05);

                .#{$component}__fixed-cell {
                    background-color: var(--c-white);
                }
            }
        }
    }

    &__cell {
        @include column-width(14rem);

        &:nth-of-type(2) { @include column-width(16rem); }
        &:nth-of-type(3) { @include column-width(12rem); }
        &:nth-of-type(4) { @include column-width(12rem); }
        &:nth-of-type(5) { @include column-width(13rem); }
    }

    &__fixed-cell {
        @include column-width(4rem);

        justify-content: center;
        position: sticky;
        right: 0;
        background-color: var(--c-white);
    }

    &__fixed-cell-content {
        padding: 0 1rem;
    }

    &__link-button {
        @extend %button--no-background;

        display: inline;
        padding: 0;
        color: currentColor;

        &:focus-visible {
            outline: none;
            text-decoration: underline;
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__view-skills-passport-button {
        width: 2rem;
        padding: .5rem;
        color: var(--c-grey-dark);
        background: none;

        &:hover,
        &:focus {
            background-color: var(--c-grey-light);
        }
    }
}
