@use "src/styles" as *;

.competency-results {

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-wrap: wrap;
        gap: .5rem;
        max-height: 24rem;
        overflow-y: auto;
        margin: 0;
        padding: 1rem 0;
    }

    &__button-wrapper {
        position: relative;
        padding-top: 1rem;

        &::before {
            content: "";
            position: absolute;
            top: -1rem;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 1rem;
            pointer-events: none;
            background: var(--box-bottom-fade);
        }
    }

    &__reset-button {
        @extend %button--transparent-background;

        color: var(--c-grey-dark);
    }
}
