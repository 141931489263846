.skills-passport-page {

    &__header {
        position: relative;
        z-index: 20;
    }

    &__tabs {
        margin-top: 1.5rem;
    }
}
