.organisation-detail-occupation-overview-skeletons {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        max-width: 10rem;
        height: 1.5rem;
    }

    &__button {
        max-width: 10rem;
        height: 3rem;
    }

    &__list {
        margin: 2rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {

        & + & {
            margin-top: 1rem;
        }
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__card-title {
        max-width: 20rem;
        height: 1.5rem;
    }

    &__card-description {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }

    &__card-action-button {
        align-self: flex-end;
        max-width: 9rem;
        height: 1.5rem;
        margin-top: 1rem;
    }
}
