@use "src/styles" as *;

.vacancy-detail-skeletons {

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }

    &__title {
        height: 1.25rem;
    }

    &__sub-title {
        height: 1.125rem;
    }

    &__header-card {
        padding: 0;
    }

    &__header-cover {
        width: 100%;
        height: 20rem;
        background-color: var(--c-grey-light);
    }

    &__header-information {
        display: flex;
        padding: 2rem;
    }

    &__avatar {
        @include square(4.5rem);

        background-color: var(--c-grey-light);
    }

    &__header-text-wrapper {
        margin-left: 1.5rem;
        width: 50%;
    }

    &__company {
        width: 13rem;
        margin-top: .75rem;
    }

    &__body {
        width: 100%;
    }

    &__about-description {
        margin-top: 1rem;
    }

    &__paragraph {

        & + & {
            margin-top: 2.5rem;
        }
    }

    &__line {

        & + & {
            margin-top: 1rem;
        }
    }

    &__skills-wrapper,
    &__competencies-wrapper {
        margin-top: 1.5rem;
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        padding: 0;
    }

    &__tag {
        display: block;
        width: 4rem;
        height: 2.25rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        padding: .5rem .75rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);
    }

    &__aside {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 20rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__property-wrapper {
        margin-top: 1.5rem;
    }
}
