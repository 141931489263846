@use "src/styles" as *;

.education-card {

    &__card {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__logo-wrapper {
        display: flex;
        align-items: center;

        @include for-size(tablet-portrait-up) {
            align-items: flex-start;
        }
    }

    &__logo {
        @include square(3.5rem);

        border: none;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__title {
        font-size: 1.125rem;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__educator {
        margin-top: .25rem;
        color: var(--c-grey-dark);
    }

    &__meta-wrapper {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &__price {
        font-weight: var(--fw-medium);
        color: var(--c-black);
    }

    &__stap-label {
        margin-left: 1rem;
    }

    &__information-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;

        &--is-compact {

            @include for-size(tablet-portrait-up) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &__property-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__description {
        margin-top: 1rem;
    }

    &__link {
        @extend %button--textual;

        align-self: flex-end;
        margin-top: 1.5rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-primary);
        white-space: nowrap;

        &::after {
            @extend %click-extend;

            content: "";
        }

        &--is-compact {

            @include for-size(tablet-portrait-up) {
                align-self: center;
                margin-top: 0;
            }
        }
    }
}
