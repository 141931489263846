@use "src/styles" as *;

.internal-occupation-detail-content {
    max-width: 70rem;

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__view-selector {
        margin-top: 1rem;
        margin-left: auto;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__card {
        margin-top: 1rem;
    }

    &__list {
        
        & + & {
            margin-top: 2rem;
        }
    }

    &__competencies-wrapper {
        margin-top: 2rem;
    }
}
