@use "src/styles" as *;

.development-plan-evaluation-competency-card {
    padding: 1.5rem 2rem;

    &__header {
        display: flex;
        flex-direction: column;
        margin-left: -1rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-left: 0
        }
    }

    &__toggle-button {
        @extend %button--no-background;

        margin-top: 1rem;
        margin-left: -1rem;
        padding: 1rem 0;
        font-size: 1rem;
        color: var(--c-primary);

        @include for-size(tablet-landscape-up) {
            margin-left: 0;
        }

        &:disabled {
            color: var(--c-grey-light);
        }
    }

    &__toggle-icon {
        font-size: 0.75rem;
        transition: transform .25s ease-out;
        transform: scaleY(1);

        &--is-expanded {
            transform: scaleY(-1);
        }
    }

    &__title {
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__action-buttons {
        display: flex;
        align-items: center;
        margin-left: 0;
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            justify-content: flex-end;
            margin-left: 1rem;
            margin-top: 0;
        }

        @include for-size(phone-only) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__grade {
        margin-left: 1rem;

        @include for-size(phone-only) {
            margin-left: 0;
        }
    }

    &__grade-label {
        font-weight: var(--fw-medium);
    }

    &__grade-option {
        padding: .75rem 1rem;

        &::after {
            background-color: var(--c-primary);
        }
    }

    &__expandable-section {
        overflow: hidden;
        max-height: 0;
        transition: max-height .5s ease-in-out;
        margin-left: -1rem;

        @include for-size(tablet-landscape-up) {
            margin-left: 0;
        }

        &--is-expanded {
            max-height: 500rem;
        }
    }

    &__input {
        margin-left: -1rem;

        @include for-size(tablet-landscape-up) {
            margin-left: 0;
        }
    }

    &__question {
        margin-top: 1rem;

        & + & {
            margin-top: 2rem;
        }
    }
}
