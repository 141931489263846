@use "src/styles" as *;

.non-sort-table {

    &__header-row {
        border-top-color: transparent;
    }

    &__sticky-column {
        @include column-width(var(--sticky-column-width));

        position: sticky;
        right: 0;
        background-color: var(--c-white);
    }

    &__hidden-cell {
        @include column-width(0);
    }

    &__redirect-button {
        @extend %button--no-background;
        @extend %click-extend;

        z-index: 10;
    }
}
