@use "src/styles" as *;

.confirm-delete-action-buttons {

    &__button {

        &--confirm {
            @extend %button--delete;
        }

        &--cancel {
            @extend %button--transparent-background;

            color: var(--c-grey-dark);
        }
    }
}
