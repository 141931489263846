@use "src/styles" as *;

.occupation-header-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__title {
        font-size: 1.25rem;
    }

    &__description {
        max-width: var(--paragraph-max-width);
        margin-top: .5rem;
        white-space: pre-wrap;
    }

    &__action-buttons {
        display: flex;
    }

    &__action-button {
        @extend %button--secondary;
        
        & + & {
            margin-left: 1rem;
        }
    }
}
