@use "src/styles" as *;

.dialog-content {

    &--is-positive &__icon-wrapper {
        background-color: var(--c-positive);
    }

    &--is-warning &__icon-wrapper {
        background-color: var(--c-warning);
    }

    &--is-negative &__icon-wrapper {
        background-color: var(--c-negative);
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__title {
        font-size: 1.25rem;
        color: var(--c-black);
    }

    &__description {
        margin-top: 1rem;
        line-height: 1.5;
        color: var(--c-grey-dark);
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        min-width: 2.5rem;
        margin-right: 1rem;
        color: var(--c-white);
    }

    &__content-wrapper {
        margin-top: 2rem;
    }
}
