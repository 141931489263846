.development-overview-skeletons {

    &__item-list {
        margin-top: 2.25rem;
        padding: 0;
        list-style-type: none;
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: 2.75rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);

        & + & {
            margin-top: 1rem;
        }
    }

    &__item-title {
        height: 1.5rem;

        & + & {
            margin-top: 1rem;
        }
    }

    &__item-content {
        height: 1rem;
        margin-top: 3rem;
        width: 100%;

        & + & {
            margin-top: 1rem;
        }
    }
}
