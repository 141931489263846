@use "src/styles" as *;

.development-plan-evaluation {
    margin-top: 2rem;

    &__header {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__title {
        font-size: 1.5rem;
    }

    &__edit-button {
        @extend %button--no-background;

        margin-left: 1.5rem;
        padding: .75rem;
        font-size: .875rem;
        color: var(--c-primary);
    }

    &__subtitle {
        margin-top: .25rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__actions-wrapper {
        display: flex;

        @include for-size(phone-only) {
            flex-direction: column;
        }
    }

    &__delete-button, &__edit-button {
        @extend %button--no-background;

        white-space: nowrap;
        color: var(--c-primary);
    }

    &__loading {
        @extend %flex-align-center;

        height: 30vh;
    }

    &__list {
        margin-top: 2rem;
    }

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }
}
