@use "src/styles" as *;

.developmentplan-overview-skeletons {
    
    &__item-list {
        margin-top: 2.25rem;
        padding: 0;
        list-style-type: none;
    }

    &__item {
        display: flex;
        padding: 2.75rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);

        & + & {
            margin-top: 1rem;
        }
    }

    &__item-title {
        height: 1.5rem;
        max-width: auto;

        @include for-size(tablet-landscape-up) {
            max-width: 50%;
        }
    }
}
