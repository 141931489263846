@use "src/styles" as *;

.sort-table {

    &__header-row {
        border: none;
    }

    &__sticky-column {
        @include column-width(4rem);

        position: sticky;
        right: 0;
        background-color: var(--c-white);
    }
}
