@use "src/styles" as *;

.occupation-link-card {
    
    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__description {
        max-width: 45rem;
        margin-top: .5rem;
    }

    &__action-button {
        @extend %button--textual;

        align-self: flex-end;
        margin-top: 1rem;
        color: var(--c-grey-dark);

        &::after {
            @extend %click-extend;

            content: "";
        }
    }
}
