.my-actions {
    max-width: 70rem;

    &__list {
        margin: 3rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {

        & + & {
            margin-top: .5rem;
        }
    }
}
