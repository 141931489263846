@use "src/styles" as *;

.skills-passport-card {
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        border-bottom: var(--line);

        & + * {
            margin-top: 2rem;
        }
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__description {
        margin-top: .5rem;
    }

    &__extra-description {
        margin-top: 1.5rem;
    }

    &__candidate-illustration {
        margin-left: 1rem;
    }

    &__loader {
        margin-top: 2rem;
    }

    &__null-state {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-top: 1.5rem;
        color: var(--c-grey-dark);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__refresh-button,
    &__call-to-action-button {
        margin-top: 1rem;
        white-space: nowrap;

        @include for-size(tablet-portrait-up) {
            margin-top: 0rem;
            margin-left: 1.5rem;
        }
    }

    &__refresh-button {
        @extend %button--secondary;
    }
}
