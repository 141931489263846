.checkbox-group-list {
    display: flex;
    flex-direction: column;

    &__item {

        & + & {
            margin-top: .75rem;
        }
    }

    &__sub-item-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: .75rem;
        padding-left: 1rem;
        border-left: 2px solid var(--c-grey-light);

        &--is-hidden {
            display: none;
        }
    }
}
