.organisation-detail-candidates {

    &__table {
        margin-top: 1rem;
    }

    &__pagination {
        margin-top: 1rem;
    }
}
