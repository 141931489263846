$component: "breadcrumbs";

.#{$component} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    color: var(--c-grey);

    &__icon {
        height: .75rem;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 2rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);

        &:first-of-type .#{$component}__label,
        &:first-of-type .#{$component}__link {
            padding-left: 0;
        }
    }

    &__label {
        padding: 0 1rem;
    }

    &__link {
        color: var(--c-grey-dark);
        padding: 0 1rem;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
