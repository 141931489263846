.text-input {
    display: block;

    &__label {
        margin-bottom: .625rem;
    }

    &__error-label {
        margin-top: .5rem;
    }
}
