@use "src/styles" as *;

.introduction-action {

    &__items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__item {
        width: 100%;

        & + & {
            margin: 1rem 0 0 0;

            @include for-size(tablet-landscape-up) {
                margin: 0 0 0 2rem;
            }
        }
    }

    &__title,
    &__description {
        margin-top: 1rem;
    }

    &__action-button {
        @extend %button--warning;

        margin-top: 2rem;
    }
}
