.table {
    display: grid;
    position: relative;
    width: 100%;
    padding: 0;
    overflow-x: auto;
    border-spacing: 0;
    border-radius: var(--border-radius);
    will-change: transform;
}
