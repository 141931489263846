@use "src/styles" as *;

.counselor-contact {

    &__contact-button {
        @extend %button--textual;

        margin-left: .25rem;
        text-decoration: underline;
    }

    &__dialog {
        max-width: 44rem;
    }

    &__email-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: initial;
        }
    }

    &__email-link {
        @extend %button;

        display: flex;
        align-items: center;
        gap: .5rem;
        text-decoration: none;
    }

    &__email-icon {
        height: 1rem;
    }

    &__copyable-value {
        flex: 1;
    }

    &__close-dialog-button {
        @extend %button--textual;

        margin-top: 2rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey);
    }
}
