@use "src/styles" as *;

.skills-passport-tabs {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 1rem;
    width: 100%;
    z-index: 10;
}
