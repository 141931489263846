@use "src/styles" as *;

.skills-card {

    &__loader,
    &__null-state {
        margin-top: 2rem;
    }

    &__skills-list {
        margin-top: 1rem;
    }

    &__dialog {
        max-width: 44rem;
    }
}
