@use "src/styles" as *;

.education-overview {

    &__skeleton-title {
        width: 20rem;
        height: 1.5rem;
    }

    &__skeleton-overview {
        max-width: 58rem;
        margin-top: 1.5rem;
    }

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__title {
        font-size: 1.5rem;
    }

    &__view-selector {
        margin-top: 1.5rem;
        margin-left: auto;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__null-state {
        width: 100%;
        margin-top: 1.5rem;
    }

    &__list {
        max-width: 58rem;
        margin-top: 1.5rem;
        padding: 0;
    }

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1.5rem;
        }
    }

    &__pagination {
        margin-top: 3rem;
    }
}
