.troubleshoot-detail-item {

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__description {
        margin-top: .5rem;
        color: var(--c-grey-dark);
    }

    &__value {
        margin-top: 1rem;
    }
}
