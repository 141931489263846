@use "src/styles" as *;

.avatar {
    @extend %flex-align-center;
    @include square(3.5rem);

    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);
    background-color: var(--c-always-white);

    &--has-fallback {
        background-color: var(--c-grey-light);
    }

    &__initials {
        font-size: 1.5rem;
        color: var(--c-grey-dark);
        user-select: none;
    
        &::after {
            content: attr(data-initials);
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: .04;
            transform:
                translate(-50%, -50%)
                rotate(-22.5deg)
                scale(3);
        }
    }

    &__image {
        width: 100%;

        &--is-cover {
            width: auto;
            height: 100%;
        }
    }
}
