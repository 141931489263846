@mixin for-size($size) {
    @if $size == tiny-screen-only {
        @media (max-width: 320px) { @content; }
    } @else if $size == phone-only {
        @media (max-width: 480px) { @content; }
    } @else if $size == tablet-portrait-up {
        @media (min-width: 768px) { @content; }
    } @else if $size == tablet-landscape-up {
        @media (min-width: 1024px) { @content; }
    } @else if $size == desktop-up {
        @media (min-width: 1448px) { @content; }
    } @else if $size == big-desktop-up {
        @media (min-width: 1800px) { @content; }
    }
}

@mixin touch-screen {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin column-width($size: 100%) {
    width: $size;
    min-width: $size;
    max-width: $size;
}

@mixin square($size: 1rem) {
    min-width: $size;
    max-width: $size;
    min-height: $size;
    max-height: $size;
}

@mixin circle($size: 1rem) {
    @include square($size);

    border-radius: $size;
}
