@use "src/styles" as *;

.occupation-detail-skills-skeletons {

    &__label {
        margin-top: 2rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &__list-item {
        height: 2.125rem;
        width: 6.5rem;
        list-style-type: none;
        border: 1px solid var(--c-grey-light);
        border-radius: var(--border-radius);
    }

    &__skill-title {
        margin-top: 2rem;
        height: 2rem;
    }

    &__skills {
        margin-top: 1rem;
    }
}
