.tag-list {

    &__title {
        margin-bottom: 0;
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__description {
        margin: .5rem 0;
        padding-bottom: 1rem;
        border-bottom: var(--line);
    }

    &__list  {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin: 0;
    }

    &__list-item {
        margin-top: 1rem;
        margin-right: 1rem;
        list-style-type: none;

        &:last-child {
            margin-right: 0;
        }
    }
}
