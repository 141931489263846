@use "index" as *;

// Colours
$c-primary:         1, 188, 156;
$c-primary-dark:    1, 163, 135;

$c-secondary:       17, 204, 204;

$c-negative:        255, 51, 52;
$c-negative-dark:   235, 26, 27;
$c-warning:         255, 153, 0;
$c-warning-dark:    230, 138, 0;
$c-notification:    46, 155, 255;

$c-background:      249, 249, 249;

$c-grey-light:      230, 232, 234;
$c-grey:            162, 169, 176;
$c-grey-dark:       95, 105, 116;

$c-dm-grey-light:   61, 77, 95;
$c-dm-grey:         118, 127, 136;
$c-dm-grey-dark:    160, 173, 186;

$c-white:           255, 255, 255;
$c-black:           33, 43, 54;
$c-full-black:      0, 0, 0;

$c-dm-white:        41, 55, 69;
$c-dm-black:        $c-white;

// Darkmode
$darkmode-enabled: true;

@mixin darkmode-variables {
    --c-white:              rgb(#{$c-dm-white});
    --c-white-rgb:          #{$c-dm-white};
    --c-black:              rgb(#{$c-dm-black});
    --c-black-rgb:          #{$c-dm-black};

    --c-background:         rgb(#{$c-black});

    --c-grey-light:         rgba(#{$c-dm-grey-light});
    --c-grey-light-rgb:     #{$c-dm-grey-light};
    --c-grey:               rgba(#{$c-dm-grey});
    --c-grey-rgb:           #{$c-dm-grey};
    --c-grey-dark:          rgba(#{$c-dm-grey-dark});
    --c-grey-dark-rgb:      #{$c-dm-grey-dark};
}

:root {
    --border-radius:        3px;
    --focus-ring:           0 0 1px 3px var(--c-primary);
    --box-shadow:           0 4px 4px 0 rgba(var(--c-grey-light-rgb), .25);
    --box-bottom-fade:      linear-gradient(0deg, rgba(var(--c-white-rgb), 1) 0%,  rgba(var(--c-white-rgb), 0) 100%);
    --line:                 1px solid var(--c-grey-light);

    --s-page-padding:       1.5rem;
    --paragraph-max-width:  40rem;

    @include for-size(tablet-landscape-up) {
        --s-page-padding:   2.5rem;
    }

    --f-primary:            'Geomanist', system-ui, sans-serif;;

    --fw-regular:           400;
    --fw-medium:            500;

    --c-primary:            rgb(#{$c-primary});
    --c-primary-rgb:        #{$c-primary};
    --c-primary-dark:       rgb(#{$c-primary-dark});
    --c-primary-dark-rgb:   #{$c-primary-dark};

    --c-secondary:          rgb(#{$c-secondary});
    --c-secondary-rgb:      #{$c-secondary};

    --c-positive:           rgb(#{$c-primary});
    --c-positive-rgb:       #{$c-primary};

    --c-negative:           rgb(#{$c-negative});
    --c-negative-rgb:       #{$c-negative};
    --c-negative-dark:      rgb(#{$c-negative-dark});
    --c-negative-dark-rgb:  #{$c-negative-dark};
    --c-notification:       rgb(#{$c-notification});
    --c-notification-rgb:   #{$c-notification};

    --c-warning:            rgb(#{$c-warning});
    --c-warning-rgb:        #{$c-warning};
    --c-warning-dark:       rgb(#{$c-warning-dark});
    --c-warning-dark-rgb:   #{$c-warning-dark};

    --c-background:         rgb(#{$c-background});

    --c-grey-light:         rgb(#{$c-grey-light});
    --c-grey-light-rgb:     #{$c-grey-light};
    --c-grey:               rgb(#{$c-grey});
    --c-grey-rgb:           #{$c-grey};
    --c-grey-dark:          rgb(#{$c-grey-dark});
    --c-grey-dark-rgb:      #{$c-grey-dark};

    --c-white:              rgb(#{$c-white});
    --c-white-rgb:          #{$c-white};
    --c-always-white:       rgb(#{$c-white});
    --c-always-white-rgb:   #{$c-white};

    --c-black:              rgb(#{$c-black});
    --c-black-rgb:          #{$c-black};
    --c-always-black:       rgb(#{$c-black});
    --c-always-black-rgb:   #{$c-black};
    --c-full-black:         rgb(#{$c-full-black});
    --c-full-black-rgb:     #{$c-full-black};

    @if $darkmode-enabled {
        @media (prefers-color-scheme: dark) {
            @include darkmode-variables;
        }

        &[data-theme="dark"] {
            @include darkmode-variables;
        }
    }
}

::backdrop {
    --c-full-black-rgb:     0, 0, 0;
    --c-full-black:         rgb(var(--c-full-black-rgb));
}
