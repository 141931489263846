.candidate-exchange-form {

    &__error-message {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 2.5rem;
    }
}
