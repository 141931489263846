.vacancy-matching-filter-skeletons {
    width: 100%;
    padding-bottom: 0;

    &__description {
        margin-top: 1.5rem;
    }

    &__line {

        & + & {
            margin-top: 0.5rem;
        }
    }

    &__filter-button {
        width: 100%;
        margin-top: 1rem;
    }

    &__skills-wrapper,
    &__competencies-wrapper {
        margin-top: 2.5rem;
    }
}
