.vacancy-property-tags {
    display: flex;
    flex-wrap: wrap;

    &__tag-item {
        margin-right: 1rem;
        margin-bottom: 1rem;
        list-style-type: none;
    }
}
