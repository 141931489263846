@use "src/styles" as *;

$component: "counselor-search-result";

.#{$component} {
    list-style-type: none;

    &__label {
        position: relative;
        display: flex;
        width: 100%;
        padding: 1rem 1.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus ~ .#{$component}__box {
            box-shadow: var(--focus-ring);
        }

        &:checked ~ .#{$component}__box {

            &::after {
                opacity: 1;
                transform: scale(.625);
            }
        }
    }

    &__box {
        @include circle(1.5rem);

        position: relative;
        border: 1px solid currentColor;

        &::after {
            @extend %click-extend;

            content: "";
            border-radius: inherit;
            background-color: currentColor;
            opacity: 0;
            transform: scale(0);
            transition: opacity .1s ease, transform .1s ease;
        }
    }

    &__text-wrapper {
        margin-left: 1.5rem;
    }

    &__name {
        font-size: 1rem;
        color: var(--c-black);
    }

    &__roles {
        font-weight: var(--fw-regular);
        color: var(--c-grey-dark);

        @include for-size(tablet-portrait-up) {
            position: absolute;
            top: 1rem;
            right: 1rem;
            font-size: .875rem;
        }
    }

    &__email {
        color: var(--c-grey-dark);
    }

    &__button {
        padding: 1rem;
    }
}
