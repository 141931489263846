@use "src/styles" as *;

.navigation-header {
    position: relative;
    z-index: 10;

    &__menu {
        z-index: 20;
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: 90%;
            max-width: var(--page-menu-width);
        }
    }

    &__top-bar {
        margin-bottom: 1.5rem;
    }
}
