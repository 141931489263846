@use "src/styles" as *;

$component: "hamburger-button";

.#{$component} {
    @include circle(2.5rem);

    padding: 0;
    color: var(--c-always-white);

    &--is-active {

        .#{$component}__bar {
            opacity: 0;

            &:first-child {
                opacity: 1;
                transform: translateY(calc(#{.625rem} - 1px)) rotate(-45deg);
            }

            &:last-child {
                opacity: 1;
                transform: translateY(calc(-#{.625rem} + 1px)) rotate(45deg);
            }
        }
    }

    &__bar-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 1.5rem;
        height: 1.25rem;
    }

    &__bar {
        width: 100%;
        height: 2px;
        border-radius: var(--border-radius);
        background-color: currentColor;
        transition: opacity .1s ease, transform .1s ease;

        &:first-child,
        &:last-child {
            transform: translateY(0) rotate(0deg);
        }
    }
}
