@use "src/styles" as *;

.vacancy-detail-header {

    &__card {
        overflow: hidden;
        padding: 0;
    }

    &__cover-wrapper {
        position: relative;
        height: 20rem;
        overflow: hidden;
        background-color: var(--c-grey-light);
    }

    &__play-button-wrapper {
        @extend %flex-align-center;
        @extend %click-extend;
    }

    &__play-button {
        @include circle(4.5rem);

        transition: transform .3s ease;

        &:hover,
        &:focus {
            transform: scale(1.2);
        }
    }

    &__play-icon {
        max-width: initial;
        height: 1.75rem;
        max-height: initial;
        transform: translateX(.25rem);
    }

    &__cover-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__information-wrapper {
        display: flex;
        padding: 2rem;
    }

    &__text-wrapper {
        margin-left: 1.5rem;
    }

    &__title {
        font-size: 1.125rem;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__company {
        margin-top: .25rem;
        color: var(--c-grey-dark);
    }

    &__publish-date {
        margin-top: .25rem;
        font-size: .875rem;
        color: var(--c-grey);
    }
}
