.expandable-navigation {
    position: absolute;
    z-index: 10;
    width: 12rem;
    padding: .5rem;
    pointer-events: none;
    border-radius: var(--border-radius);
    border: 1px solid rgba(var(--c-grey-light-rgb), .5);
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);
    transform: translateY(-.5rem);
    opacity: 0;
    transition: transform .1s ease, opacity .1s ease;

    &--is-active {
        pointer-events: all;
        transform: translateY(0);
        opacity: 1;
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        list-style-type: none;
    }

    &__button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 1rem;
        font-size: .875rem;
        color: var(--c-grey-dark);
        background-color: transparent;

        &:hover,
        &:focus {
            color: var(--c-black);
            background-color: var(--c-grey-light);
        }
    }
}
