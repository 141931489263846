@use "src/styles" as *;

$component: "sort-label";

.#{$component} {
    position: relative;
    height: auto;
    padding: 1rem 0;
    color: var(--c-grey);

    &--is-active {

        .#{$component}__icon {
            opacity: 1;
        }
    }

    &--align-right {

        .#{$component}__button {
            flex-direction: row-reverse;
            justify-content: flex-start;
        }

        .#{$component}__icon {
            margin-left: 0;
            margin-right: .5rem;
        }
    }

    &__button {
        @extend %button--no-background;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        color: currentColor;
        white-space: nowrap;

        &:focus-visible {
            outline: none;
            text-decoration: underline;
            color: var(--c-black);
        }

        &:disabled {
            color: var(--c-grey);
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__icon {
        width: 1rem;
        margin-left: .5rem;
        opacity: 0;
    }
}
