@use "src/styles" as *;

$component: "button-list";

.#{$component} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;

    &--align-center {
        align-items: center;
    }

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        align-items: center;

        &--align-center {
            justify-content: center;
        }

        &--align-right {
            flex-direction: row-reverse;

            .#{$component}__button + .#{$component}__button {
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }

    &__button {
        list-style-type: none;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }
}
