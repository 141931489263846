@use "src/styles" as *;

.top-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(tablet-landscape-up) {
        flex-direction: row-reverse;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .75rem var(--s-page-padding);
        color: var(--c-always-white);
        background-color: var(--c-primary);

        @include for-size(tablet-landscape-up) {
            padding: 0;
            color: var(--c-black);
            border-radius: var(--border-radius);
            background-color: var(--c-white);
            box-shadow: var(--box-shadow);
        }
    }

    &__sidebar-button-wrapper {
        display: flex;
        align-items: center;

        @include for-size(tablet-landscape-up) {
            padding-left: 1rem;
        }
    }

    &__sidebar-button {
        padding: 1rem;
    }

    &__profile-navigation {
        order: -1;
        margin-right: auto;

        @include for-size(tablet-landscape-up) {
            order: initial;
            margin-right: initial;
        }
    }

    &__hamburger-wrapper {
        @extend %flex-align-center;

        z-index: 30;
        margin-left: .5rem;

        @include for-size(tablet-landscape-up) {
            display: none;
            visibility: hidden;
        }
    }

    &__page-title {
        margin-top: 1.5rem;
        padding: 0 var(--s-page-padding);
        font-size: 1.5rem;
        color: var(--c-black);

        @include for-size(tablet-landscape-up) {
            margin: 0;
            padding: 0;
        }
    }
}
