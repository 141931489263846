@use "src/styles" as *;

.filter-button {
    @extend %button--secondary;

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    padding: 1rem 1.5rem;

    &__icon {
        width: 1.25rem;
        margin-right: 1rem;
    }

    &__active-filter-count {
        @extend %flex-align-center;
        @include circle(1.5rem);

        margin-left: 1rem;
        font-size: .875rem;
        line-height: 1;
        color: var(--c-grey-dark);
        background-color: var(--c-grey-light);
    }
}
