@use "src/styles" as *;

.month-year-input {
    display: block;

    &__label {
        margin-bottom: .625rem;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__input {
        width: 100%;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }

    &__error-label {
        margin-top: .5rem;
    }
}
