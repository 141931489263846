.vacancy-matching-filter {

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-medium);
    }

    &__description {
        margin-top: 1rem;
    }

    &__skills-wrapper {
        margin-top: 2.5rem;
    }

    &__competencies-wrapper,
    &__competencies-skeletons {
        margin-top: 1.5rem;
    }

    &__filter-button {
        width: 100%;
        margin-top: 1rem;
    }

    &__null-state {
        display: flex;
        margin-top: .5rem;
    }

    &__null-state-icon {
        height: 1.25rem;
        min-width: 1.25rem;
        color: var(--c-warning);
        transform: translateY(.25rem);
    }

    &__null-state-text {
        margin-left: 1rem;
    }

    &__dialog {
        max-width: 44rem;
    }
}
