.logout-illustration {
    --logout-illustration-c-skin: #ffb8b8;
    --logout-illustration-c-hair: #212b36;
    --logout-illustration-c-pants: #006da3;

    width: 8rem;

    &__background {
        fill: var(--c-grey-light);
    }

    &__hair {
        fill: var(--logout-illustration-c-hair);
    }

    &__skin {
        fill: var(--logout-illustration-c-skin);
    }

    &__shirt {
        fill: var(--c-primary);
    }

    &__pants {
        fill: var(--logout-illustration-c-pants);
    }

    &__door-board {
        fill: var(--c-grey);
    }

    &__door-knob {
        fill: var(--c-white);
    }

    &__pane {
        fill: var(--c-white);
    }
}
