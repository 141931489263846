.select {
    cursor: pointer;
    border-radius: var(--border-radius);

    &--is-disabled {
        cursor: not-allowed !important;
        opacity: .5;
    }

    &--has-error {
        border: 2px solid var(--c-negative);
    }

    &__control {
        color: var(--c-black) !important;
        border-color: var(--c-grey-light) !important;
        background-color: var(--c-white) !important;
    }

    &__value-container {
        padding: .75rem !important;
    }

    &__indicator-separator {
        margin: 0 !important;
        background-color: var(--c-grey-light) !important;
    }

    &__dropdown-indicator {
        padding: .875rem !important;
        color: var(--c-grey-dark) !important;
    }

    &__placeholder {
        color: var(--c-grey) !important;
    }

    &__single-value {
        color: var(--c-grey-dark) !important;
    }

    &__menu {
        background-color: var(--c-white) !important;
    }

    &__menu-list {
        max-height: 14rem !important;
    }

    &__option {
        color: var(--c-black) !important;

        &--is-focused {
            color: var(--c-always-white) !important;
            background-color: var(--c-primary) !important;
        }

        &--is-selected {
            color: var(--c-always-white) !important;
            background-color: var(--c-primary-dark) !important;
        }
    }
}
