.tag {
    display: inline-flex;
    align-items: center;
    padding: .5rem;
    font-size: .875rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);

    &--is-active {
        border-color: var(--c-primary);
        background-color: rgb(var(--c-primary-rgb), .05);
    }

    &--is-warning {
        border-color: var(--c-warning);
        background-color: rgb(var(--c-warning-rgb), .1);
    }

    &--is-small {     
        padding: .25rem .5rem;
    }

    &__icon {
        width: 1rem;
        margin-right: .5rem;
    }
}
