@use "src/styles" as *;

.organisation-overview {

    &__header {
        display: flex;

        @include for-size(tablet-landscape-up) {
            justify-content: flex-end;
        }
    }

    &__table {
        margin-top: 2rem;
    }

    &__pagination {
        margin-top: 1rem;
    }
}
