@use "src/styles" as *;

.form-action-buttons {

    &__button {

        &--cancel {
            @extend %button--transparent-background;

            color: var(--c-grey-dark);
        }
    }
}
