@use "src/styles" as *;

$component: "sidebar";

.#{$component} {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    max-width: 32rem;
    overflow: hidden;
    pointer-events: none;

    &--is-expanded {
        pointer-events: all;

        .#{$component}__wrapper {
            transform: translateX(0);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        border-left: var(--line);
        background-color: var(--c-white);
        transform: translateX(100%);
        transition: transform .3s ease;
    }

    &__header {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 20;
        padding-left: 1rem;
        color: var(--c-black);
        background-color: var(--c-white);
        border-bottom: var(--line);
        box-shadow: var(--box-shadow);

        @include for-size(tablet-landscape-up) {
            padding-left: 2rem;
        }
    }

    &__icon {
        max-width: 1rem;
        margin-right: 1rem;
    }

    &__title {
        margin-right: auto;
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        line-height: 1;
    }

    &__loader {
        @include circle(1.125rem);

        margin-left: auto;
        color: var(--c-primary);
    }

    &__close-button {
        @extend %button--textual;
        @extend %flex-align-center;

        color: var(--c-black);
        padding: 1.5rem 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 1.5rem 2rem;
        }
    }

    &__close-button-icon {
        height: 1.25rem;
    }

    &__child-wrapper {
        display: flex;
        position: relative;
        z-index: 10;
        overflow: hidden;
    }
}
