@use "src/styles" as *;

.view-selector-option {
    display: flex;
    align-items: center;
    position: relative;
    padding: .75rem 1.25rem;
    font-weight: var(--fw-medium);
    color: var(--c-primary);
    cursor: pointer;
    background-color: var(--c-white);

    &::after {
        @extend %flex-align-center;
        @extend %click-extend;

        content: "";
        z-index: 10;
        color: var(--c-white);
        background-color: var(--c-primary);
        opacity: 0;
        transition: opacity .1s ease;
    }

    &--is-checked {
        color: var(--c-always-white);

        &::after {
            opacity: 1;
        }
    }

    &--is-disabled#{&}--is-checked {
        color: var(--c-grey-light);
    }

    &--is-disabled {
        cursor: not-allowed;
        color: var(--c-grey);
        background-color: var(--c-grey-light);

        &::after {
            background-color: var(--c-grey);
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__icon,
    &__label {
        position: relative;
        z-index: 20;
        color: currentColor;
    }

    &__icon {
        height: auto;
        width: 1.25rem;
    }

    &__label {
        margin-left: .625rem;
    }
}
