@use "src/styles" as *;

.vacancy-card {

    &__card {
        padding-bottom: 1rem;
    }

    &__header {
        display: flex;
    }

    &__logo {
        @include square(4.5rem);
    }

    &__title-wrapper {
        margin-left: 1.5rem;
    }

    &__link {
        color: var(--c-black);
        text-decoration: none;

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 30;
        }
    }

    &__title {
        font-size: 1.125rem;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__company {
        margin-top: .25rem;
        color: var(--c-grey-dark);
    }

    &__publish-date {
        margin-top: .25rem;
        font-size: .875rem;
        color: var(--c-grey);
    }

    &__property-list {
        margin-top: 1rem;
        padding: 0;
    }
}
