.vacancy-detail-aside {

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }

    &__card-title {
        font-size: 1.125rem;
    }

    &__card-description {
        margin-top: 1rem;
    }

    &__link-button {
        margin-top: 1.5rem;
    }

    &__property-wrapper {
        margin-top: 1.5rem;
    }

    &__property {

        & + & {
            margin-top: 1.5rem;
        }
    }
}
