@use "src/styles" as *;

.talents-card {
    --talent-row-min: 2;
    --talent-row-max: 2;

    @include for-size(tablet-portrait-up) {
        --talent-row-min: 2;
        --talent-row-max: 3;
    }

    @include for-size(desktop-up) {
        --talent-row-min: 4;
        --talent-row-max: 5;
    }
   
    &__list {
        display: grid;
        grid: auto / repeat(calc(var(--talent-row-min) * var(--talent-row-max)), 1fr);
        place-items: center;
        gap: .5rem;
        margin-bottom: 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {
        @extend %flex-align-center;
        @include square(9.875rem);

        grid-column: span var(--talent-row-max);

        @include for-size(tablet-portrait-up) {

            &:nth-child(5n),
            &:nth-child(5n-1),
            &:nth-child(5n-2) {
                grid-column: span var(--talent-row-min);
            }
        }

        @include for-size(desktop-up) {

            &:nth-child(5n),
            &:nth-child(5n-1),
            &:nth-child(5n-2) {
                grid-column: span var(--talent-row-max);
            }

            &:nth-child(9n),
            &:nth-child(9n-1),
            &:nth-child(9n-2),
            &:nth-child(9n-3),
            &:nth-child(9n-4) {
                grid-column: span var(--talent-row-min);
            }
        }
    }
}
