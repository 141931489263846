%html-format {

    & > * + * {
        margin-top: 1rem;
    }

    h1 {
        font-size: 1.25rem;
    }

    h2 {
        font-size: 1.125rem;
    }

    h3 {
        font-size: 1rem;
    }

    h2 + p,
    h3 + p {
        margin-top: 0;
    }

    ul, ol {
        padding-left: 1.125rem;
    }

    a {
        color: var(--c-primary);
    }
}
